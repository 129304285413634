import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setPartnerSearchText } from "../../redux/partner/partnerSlice";
import { setSubadminFilterDetails } from "../../redux/sub-admin/subAdminSlice";
import { setUnversityFilterDetails } from "../../redux/universities/universitySlice";
import { setStudentsFilterDetails, setCourseSearchText } from "../../redux/students/studentsSlice";
import { setInstituteProdFilterDetails } from "../../redux/instituteProd/instituteProdSlice";
import { setCustomPageSearchText } from "../../redux/customPages/customPageSlice";
import { setLeadSearchText } from "../../redux/lgs/lgsSlice";

function SearchTextField({ searchText, handleSearchText, fromWhere }) {
  const dispatch = useDispatch();
  return (
    <div>
      <TextField
        id="standard-basic"
        label="Search Here"
        className="text-field"
        value={searchText}
        sx={{
          width: '556px',
        }}
        onChange={(e) => {
          fromWhere === "subscribedUsers" &&
            dispatch(setSubadminFilterDetails(e.target.value));
          fromWhere === "meal" &&
            dispatch(setUnversityFilterDetails(e.target.value));
          fromWhere === "students" &&
            dispatch(setStudentsFilterDetails(e.target.value));
          fromWhere === "product" &&
            dispatch(setInstituteProdFilterDetails(e.target.value));
          fromWhere === "partner" &&
            dispatch(setPartnerSearchText(e.target.value));
          fromWhere === "customPage" &&
            dispatch(setCustomPageSearchText(e.target.value));
          fromWhere === "lead" &&
            dispatch(setLeadSearchText(e.target.value));
          fromWhere === "availableCourses" &&
            dispatch(setCourseSearchText(e.target.value));
        }}
        InputProps={{
          endAdornment: (
            <IconButton variant="outlined" onClick={(e) => handleSearchText(e)}>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default SearchTextField;
