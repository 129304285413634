import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Moment from "moment";
import { Avatar } from "@mui/material";
import placeholder from "../../assest/images/download.jfif";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const useStyle = makeStyles({
  table: {
    borderCollapse: "separate !important",
    borderSpacing: "0px 10px !important",
  },
  tableHead: {
    borderRadius: "4px",
    boxShadow: '0px 2px 4px rgb(0, 0, 0, 0.5)',
    backgroundColor: '#fff'
  },
  tableHeadCell: {
    fontWeight: "600 !important",
    fontSize: "20px !important",
    padding: "20px",
  },
  tableRow: {
    backgroundColor: "#fff",
    borderBottom: '1px solid red',
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
    },
  },

  tableBodyCell: {
    fontSize: "18px !important",
  },
});
//before using this component do not forget to match the column_name to the field in rows you want to display

//demo
// column = ["name", "phone"]
// rows = [
//   { name: "himani", phone: 885623 ,score:A},
//   { name: "riya", phone: 885623 ,score:B},

// ]
// table:-
// name | phone;
// himani | 885623;
// riya | 885623;

export default function BasicTable({ rows, columns, where }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(rows);
  const makeFirstLetterCapital = (str) => {
    if (str.includes(" ")) {
      let a = str.split(" ");
      a = a.map((i) => makeFirstLetterCapital(i));
      return a.join(" ");
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const availableWheres = [
    "recipes",
    "ingredients",
    "workout",
    "posts",
    "shop",
  ];

  const handleOnClickRow = (id) => {
    if (availableWheres.find((w) => w === where)) {
      if (where === "recipes") {
      }
      if (where === "ingredients") {
      }
      if (where === "workout") {
      }
      if (where === "posts") {
      }
      if (where === "shop") {
      }
    }
  };
  return (
    <>
      <Table sx={{ minWidth: 650 }} className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell className={classes.tableHeadCell} key={index}>
                {makeFirstLetterCapital(col.split("_").join(" "))}
                {/* {col.charAt(0).toUpperCase() + col.slice(1)} */}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {rows && rows.length > 0 ? (
          <TableBody>
            {rows.map((row, index) => (
              <TableRow className={classes.tableRow} key={index}>
                {columns.map((i) => (
                  <TableCell
                    className={classes.tableBodyCell}
                    key={i}
                    onClick={(e) => handleOnClickRow(row["_id"])}
                    sx={{
                      maxWidth: "345px",
                      overflow: "hidden",
                      maxHeight: "200px",
                    }}
                  >
                    {((i === "trainer_image" && row[i]) ||
                      (i === "recipe_image" && row[i]) ||
                      (i === "Ingredient_Image" && row[i]) ||
                      (i === "shop_image" && row[i]) ||
                      (i === "post_image" && row[i])) && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {typeof row[i] === "object" && row[i].length > 1 ? (
                          <Avatar
                            src={row[i][0]}
                            style={{
                              width: 100,
                              height: 100,
                              display: "inline-flex",
                            }}
                            alt=""
                            variant="square"
                          >
                            <img
                              src={placeholder}
                              width="100"
                              height="100"
                              alt=""
                            />
                          </Avatar>
                        ) : (
                          <Avatar
                            src={row[i]}
                            style={{
                              width: 100,
                              height: 100,
                              display: "inline-flex",
                            }}
                            alt=""
                            variant="square"
                          >
                            <img
                              src={placeholder}
                              width="100"
                              height="100"
                              alt=""
                            />
                          </Avatar>
                        )}
                        {typeof row[i] === "object" && row[i].length > 1 && (
                          <span style={{ marginLeft: "10px" }}>
                            +{row[i].length - 1}
                          </span>
                        )}
                      </div>
                    )}
                    {i === "Image" && <Avatar src={row[i]} />}
                    {(i === "id" || i === "Id" || i === "SNo") && index + 1}
                    {/* {i.toLowerCase().includes("date") &&
                      Moment(row[i]).format("DD MMMM YYYY")} */}
                    {i === "University / Coaching center" && (
                      row?.university_name || row?.coaching_name)}
                    {i === "Name of University" && row?.university_name}
                    {i === "Tags" && row?.tags?.map((item, index) => (
                      index + 1 === row?.tags.length ? `${item}` : `${item}, `
                    ))}
                    {i === "Name" && row?.name}
                    {i === "Course Name" && row?.name}
                    {i === "Type" && (
                      (row?.partner_type === "admission_consultancy" && "Admission Consultancy") ||
                      (row?.partner_type === "coaching" && "Coaching") ||
                      (row?.page_type))}
                    {i === "Category Domain" && row?.category_domain}
                    {i === "Experience" && `${row?.experience_in_years} Years`}
                    {i === "Title" && row?.title}
                    {i === "Name of Chairperson" && row?.name}
                    {i === "Phone Number" && row?.phone}
                    {i === "Name of person" && row?.name}
                    {i === "Rating" && row?.rating}
                    {i === "Email ID" && row?.email}
                    {i === "Username" && row?.username}
                    {i === "Name of Owner" && row?.owner_name}
                    {i === "Name of Coaching Center" && row?.coaching_name}
                    {i === "city" && row?.information?.city}
                    {i === "Full Address" && (row?.information?.address || row?.address)}
                    {i === "Website Link" && row?.information?.website_link}
                    {i === "Name Of Student" && row?.name}
                    {i === "Course Name" && row?.course_interested_in}
                    {i === "Name of Product" && row?.name}
                    {i === "Category" && row?.category}
                    {i === "Level" && row?.level}
                    {i === "Stream" && row?.stream}
                    {i === "Type" && row?.product_type}
                    {i === "DOB" && Moment(row?.dob).format("DD MMMM YYYY")}
                    {i === "Created Date" && Moment(row?.created_at).format("DD-MM-YYYY")}
                    {i === "Order Date" && Moment(row?.created_at).format("DD-MM-YYYY")}
                    {i === "Address" && row?.address}
                    {i === "Order Id" && row?.order_id?.split("_")[1]}
                    {i === "Payment Id" && (row?.payment_id?.split("_")[1] || "-")}
                    {i === "Review" && (row?.message || row?.video_url)}

                    {i.toLowerCase().includes("price") &&
                      row[i] === "0" &&
                      "free"}
                    {
                      !(
                        i === "trainer_image" ||
                        i === "post_image" ||
                        i === "recipe_image" ||
                        i === "shop_image" ||
                        i === "Ingredient_Image"
                      ) &&
                        !i.toLowerCase().includes("date") &&
                        !(
                          i.toLowerCase().includes("price") && row[i] === "0"
                        ) &&
                        !(i === "Image") &&
                        (row[i]?.length > 45 ? (
                          <div>{`${row[i].substring(0, 45)}...`}</div>
                        ) : (
                          <div>{row[i]}</div>
                        ))
                    }
                    {!row[i] && i !== "Image" && i !== "id" && i !== "SNo" 
                      && i !== "Name of University" && i !== "Name of Chairperson" 
                      && i !== "Phone Number" && i !== "Email ID" && i !== "Name of Owner" &&
                      i !== "Name of Coaching Center" && i !== "city" && i !== "Full Address" &&
                      i !== "Website Link" && i !== "Name Of Student" && i !== "Course Name" &&
                      i !== "Name of Product" && i !== "Category" && i !== "Type" && 
                      i !== "DOB" && i !== "Address" && i !== "University / Coaching center"
                      && i !== "Tags" && i !== "Title" && i !== "Name of person" && 
                      i !== "Rating" && i !== "Review" && "-" && i !== "Name" && 
                      i !== "Level" && i !== "Stream" && i !== "Order Date"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <caption>No Rows Found</caption>
        )}
      </Table>
    </>
  );
}
