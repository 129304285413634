import axios from "axios";
import endpoint from "../../index.routes";

const getUniversities = async (params) => {
    const { limit, offset, searchText, request_status } = params;
    // console.log("limit===",limit, "offset===",offset, "searchText=====================>",request_status);
    const response = await axios.get(
      endpoint.UNIVERITY,
      {
        params: searchText ? { limit, offset, searchText, request_status } : { limit, offset, request_status },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};

const postSignUpRequest = async (params) => {
  const { data } = params;
  let path = params.university_name ? endpoint.SIGNUP_REQUEST : endpoint.COACHING_SIGNUP_REQUEST
  const response = await axios.post(path, params, {
    headers: { token: localStorage.getItem("hp_token") },
  });
  return response.data;
};

const verify = async (params) => {
  const { data } = params;
  const response = await axios.post(endpoint.VEIRY, params, {
      headers: { token: localStorage.getItem("hp_token") },
  });
  return response.data;
};

const addListing = async (params) => {
  const response = await axios.post(endpoint.ADDLISTING, params, {
    headers: { token: localStorage.getItem("hp_token") },
  });
  return response.data;
};

const getUniversityById = async (params) => {
  const { universityId } = params;
  const response = await axios.get(
    endpoint.UNIVERITY + "/" + universityId,
    {
      // params: { limit, offset },
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const updateData = async (params) => {
  const response = await axios.put(`${endpoint.UPDATE_UNIVERSITY}/${params?.id}`, 
    params?.body, {
    headers: { token: localStorage.getItem("hp_token") },
  });
  return response.data;
}

const getUniversityByToken = async() => {
  const response = await axios.get(endpoint.UNIVERISTY_BY_TOKEN, {
      headers: { token: localStorage.getItem("new_institute_token") },
  });
  return response.data;
}

const universityStatusUpdate = async (params) => {
  const {
    request_status, 
    reject_reason, 
    id,
    from,
    changes,
    information_check } = params;
  let body = {};
  if(from === "reject"){
    body = {request_status, reject_reason}
  }
  if(from === "review"){
    body = {request_status, changes, information_check}
  }
  if(from === "approve"){
    body = {request_status}
  }
  if(from === "registration"){
    body = {request_status, changes}
  }
  if(from === "unblock"){
    body = {request_status}
  }
  if(from === "block"){
    body = {request_status}
  }
  const response = await axios.put(
    endpoint.UNIVERITY + "/" + id, body,
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}
const updateUniversity = async(params) => {
  const response = await axios.put(
    endpoint.UNIVERITY + "/",
    {
      headers: { token: localStorage.getItem("hp_token") }
    }
  );
  return response.data;
}

const universityServices = { 
    getUniversities, 
    getUniversityById,
    universityStatusUpdate,
    addListing,
    updateUniversity,
    verify,
    postSignUpRequest,
    updateData,
    getUniversityByToken,
};

export default universityServices;
