import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Chip from '@mui/material/Chip';
import Dialog from "@mui/material/Dialog";
import routes from "../../react_routes_enum";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import UserTable from "../../components/BasicTable/BasicTable";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import { getAvailableCourses, getAvailableCourseById } from "../../redux/students/studentsSlice";
import { TextField, InputLabel, IconButton, Typography, Button } from "@mui/material";

const AvailableCourses = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);
    const [orderData, setOrderData] = useState({});
    const [userListView, setUserListView] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const { 
        isError,
        message,
        isLoading,
        totalCount,
        courseSearchText,
        availableCourseData } = useSelector((state) => state.students)
  
    const columns = [
        "SNo",
        "Created Date",
        "Course Name",
        "Category",
        "Action"
    ];

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const resetStateForPaginationOfDifferentView = () => {
        setLimit(10);
        setOffset(0);
        setPage(0);
    };

    useEffect(() => {
        const res = dispatch(getAvailableCourses({limit, offset}));
        res?.then((r) => {
            if(r.payload.message === "unauthorized" || 
                r.payload.message === "Unauthorized" ||
                r.payload.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
            }
        })
    }, [limit, offset]);

    const handleSearchText = (e) => {
        resetStateForPaginationOfDifferentView();
        if (limit === 10 && offset === 0) {
          const res = dispatch(getAvailableCourses({ limit, offset, searchText: courseSearchText}));
          res?.then((r) => {
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
              r?.payload?.message === "Session Expired"){
              toast.error("Session expired, please login again");
              localStorage.removeItem("hp_token");
              localStorage.removeItem("hp_user");
              navigate(routes.LOGIN);
            }
          })
        }
    };

    const courseDetail = (id) => {
        const res = dispatch(getAvailableCourseById({id}));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                navigate(routes.EDIT_COURSE);
                return;
            }
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    if (isLoading) return <Spinner />;

    if (isError) return <h1 style={{ color: "red" }}>{message}</h1>;
  
    return (
        <div style={{ marginTop: '5%' }}>
        <Dialog maxWidth="sm" open={isDialogOpen} onClose={handleClose} fullWidth>
            <DialogContent>
                <h3 style={{
                    fontSize: '24px',
                    textAlign: 'center',
                    marginBottom: '0px'
                }}>
                    Student & Course Detail
                </h3>
                <div style={{
                    height: '20px',
                    width: '100%'
                }}></div>
                <div className="order-customer">
                    {orderData?.name &&
                        <div>
                            <span>Name : </span>
                            <span>{orderData?.name}</span>
                        </div>
                    }
                    {orderData?.email &&
                        <div>
                            <span>Email : </span>
                            <span>{orderData?.email}</span>
                        </div>
                    }
                    {orderData?.phone &&
                        <div>
                            <span>Phone : </span>
                            <span>{orderData?.phone}</span>
                        </div>
                    }
                    {orderData?.address &&
                        <div>
                            <span>Address : </span>
                            <span>{orderData?.address}</span>
                        </div>
                    }
                </div>
                <div>
                    <h3 
                        style={{
                            fontSize: '20px',
                            marginTop: '8px',
                            marginBottom: '4px',
                            color: 'var(--secondary-color)'
                        }}
                    >
                        Course details : 
                    </h3>
                    {orderData?.courses?.map((item, index) => (
                        <div key={index} className="order-course">
                            <div>
                                <span>University Name : </span>
                                <span>{item?.university_name}</span>
                            </div>
                            <div>
                                <span>City : </span>
                                <span>{item?.university_name}</span>
                            </div>
                            <div className="course-heading">
                                <h3>Course Name</h3>
                                <h3>Course Duration</h3>
                                <h3>Course Fees</h3>
                            </div>
                            {item?.item?.map((it, ind) => (
                                <div
                                    key={`${index}${ind}`}
                                    className="course-body"
                                >
                                    <p>{it.course}</p>
                                    <p>{it.duration}</p>
                                    <p>{it.fees}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </DialogContent>
        </Dialog>

        {userListView && (
            <>
                <SearchTextField
                    fromWhere="availableCourses"
                    searchText={courseSearchText}
                    handleSearchText={handleSearchText}
                />
                <div style={{textAlign: 'right'}}>
                    <Button
                        variant="contained"
                        onClick={() => {navigate(routes.ADD_COURSE)}}
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: '#F69220',
                            textTransform: 'capitalize'
                        }}
                    >
                        <ControlPointIcon sx={{marginRight: '4px'}} />
                        Add New Course
                    </Button>
                </div>
                <Pagination
                    totalCount={totalCount}
                    limit={limit}
                    page={page}
                    changeLimit={setLimit}
                    changeOffset={setOffset}
                    changePage={setPage}
                />
                <UserTable
                    columns={columns}
                    rows={
                        availableCourseData &&
                        availableCourseData?.map((item) => {
                        const res = {
                            ...item,
                            Status: (
                                <>
                                    {item?.status === "in_progress" &&
                                        <Chip
                                            color="warning"
                                            label="In Progress"
                                        />
                                    }
                                    {item?.status === "completed" &&
                                        <Chip
                                            color="success"
                                            label="Completed"
                                        />
                                    }
                                    {item?.status === "failed" &&
                                        <Chip label="Failed" color="error" />
                                    }
                                </>
                            ),
                            Action: (
                                // <IconButton
                                //     color="inherit"
                                //     edge="start"
                                //     sx={{
                                //         display: 'flex',
                                //         flexDirection: 'column',
                                //     }}
                                //     onClick={() => {
                                //         setIsDialogOpen(true)
                                //         setOrderData(item)
                                //     }}
                                // >
                                //     <VisibilityIcon sx={{color: '#3C1CDE'}} />
                                //     <Typography
                                //         component="p"
                                //         variant="p"
                                //         sx={{
                                //             color: '#3C1CDE',
                                //             fontSize: '18px',
                                //             fontWeight: 'bold'
                                //         }}
                                //     >
                                //         View
                                //     </Typography>
                                // </IconButton>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                    onClick={() => courseDetail(item._id)}
                                >
                                    <BorderColorIcon sx={{color: '#ECAB2D'}} />
                                    <Typography
                                        variant="p"
                                        component="p"
                                        sx={{
                                        color: '#ECAB2D',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        marginTop: '6px'
                                        }}
                                    >
                                        Edit
                                    </Typography>
                                </IconButton>
                            )
                        };
                        return res;
                    })
                    }
                />
            </>
        )}
        </div>
    );
};

export default AvailableCourses;