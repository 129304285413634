import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  Button, 
  Typography, 
  InputLabel,
  MenuItem,
  Select,
  TextField } from "@mui/material";
import routes from "../../react_routes_enum";
import AddIcon from '@mui/icons-material/Add';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import LoaderIcon from "../../components/loader/Loader";
import ClearIcon from '@mui/icons-material/Clear';
import UploadBox from '../../components/UploadBox/UploadBox';
import { updateData } from "../../redux/universities/universitySlice";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px',
        fontWeight: 'bold'
    },
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    horizontalLine: {
        width: '200px',
        height: '4px',
        backgroundColor: 'var(--primary-color)',
    },
    formIconsBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '5%',
        marginRight: '14%',
    },
    iconStyle: {
        backgroundColor: 'var(--primary-color)',
        height: '52px',
        width: '52px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '800px'
    },
    icon: {
        color: '#fff',
    },
    iconText: {
        color: 'var(--primary-color)',
        fontSize: '18px !important',
        marginTop: '8px !important',
        fontWeight: 'bold',
    },
    iconBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute', 
        top: -24
    },
    leftBox: {
        height: '200px',
        width: '200px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '15px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '122px',
        height: '34px'
    },
    checkBox: {
        color: 'var(--primary-color)',
        '&.Mui-checked': {
            color: 'var(--primary-color)',
        }
    },
    table: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        marginLeft: '0.8%' 
    },
    tableTh: {
        borderBottom: '1px solid rgb(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    tableTd: {
        borderRight: '1px solid rgb(0, 0, 0, 0.2)',
        width: '14.5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
}))

let placement = {
    placement_records_check: 1,
    placement_records: {
        companies_logo: [],
        brochure: "",
        record: [
            {
                year: "",
                description: "",
                logo: []
            }
        ]
    }
}


const Listing = () => {
    const [btnLoader, setBtnLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [placementRecords, setPlacementRecords] = React.useState(placement);
    const [yearRange, setYearRange] = React.useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const { 
        universityById,
        universityByToken } = useSelector(
        (state) => state.university
    );
    const saveAndNext = () => {
        if(universityById?.placement_records_check && !isEdit){
            navigate(routes.DETAILS);
        }else{
            let body = {};
            let yearEror = false;
            let descriptionError = false;
            placementRecords.placement_records.record.map((item) => {
                if(item.year === ""){
                    yearEror = true;
                    // eslint-disable-next-line array-callback-return
                    return;
                }
                if(item.description === ""){
                    descriptionError = true;
                    // eslint-disable-next-line array-callback-return
                    return;
                }
            })
            if(yearEror){
                toast.error("Year Required");
                return;
            }
            if(descriptionError){
                toast.error("Description Required");
                return;
            }else{
                if(placementRecords?.placement_records?.companies_logo?.length === 0){
                    body = {
                        placement_records_check: 1,
                        placement_records: {
                            brochure: placementRecords?.placement_records?.brochure,
                            record: placementRecords?.placement_records?.record
                        }
                    }
                }
                if(placementRecords?.placement_records.brochure === ""){
                    body = {
                        placement_records_check: 1,
                        placement_records: {
                            companies_logo: placementRecords?.placement_records?.companies_logo,
                            record: placementRecords?.placement_records?.record
                        }
                    }
                }
                if(placementRecords?.placement_records?.brochure === "" && placementRecords?.placement_records?.companies_logo?.length === 0){
                    body = {
                        placement_records_check: 1,
                        placement_records: {
                            record: placementRecords?.placement_records?.record
                        }
                    }
                }
                if(placementRecords?.placement_records?.brochure !== "" && placementRecords?.placement_records?.companies_logo?.length > 0){
                    body = placementRecords
                }
                setBtnLoader(true);
                let res = dispatch(updateData({type: 'University', body, 
                    id: universityById?._id ? universityById?._id : 
                    universityByToken?.data?._id}));
                res.then((r) => {
                    setBtnLoader(false);
                    if(r.payload.statusCode === 200){
                        toast.success(r?.payload?.message);
                        localStorage.removeItem("new_institute_token");
                        navigate(routes.UNIVERSITY_REGISTRATION);
                    }else{
                        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                            r?.payload?.message === "Session Expired"){
                            toast.error("Session expired, please login again");
                            localStorage.removeItem("hp_token");
                            localStorage.removeItem("hp_user");
                            navigate(routes.LOGIN);
                            return;
                        }
                        toast.error(r?.payload?.message);
                    }
                })
            }
        }
    }
    const onUploadChange = async(name, value) => {
        if(name.split("_")[0] === "logo"){
            let localArray = [];
            let newArray = [];
            let array = [...placementRecords.placement_records.record[parseInt(name?.split("_")[2])]?.logo];
            localArray = [...placementRecords.placement_records.record];
            array.push(value);
            newArray = localArray.map((element, i) => {
                return {...element, 
                    ...(name === `logo_box_${i}` && {
                    logo: [...array]
                })}
            })
            setPlacementRecords({
                ...placementRecords,
                placement_records: {
                    ...placementRecords.placement_records,
                    record: [...newArray]
                }
            })
        }else{
            setPlacementRecords({
                ...placementRecords, 
                placement_records: {
                    ...placementRecords.placement_records, 
                    brochure: value}})
        }
    }
    React.useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.data){
                r?.payload?.data?.map((item) => {
                    if(item?.configuration_type === "year_range"){
                        setYearRange(item?.data);
                    }
                })
            }
        })
        if(universityById?.placement_records_check){
            setPlacementRecords({...placementRecords, 
                placement_records: universityById?.placement_records})
        }
    }, [])

    const addMoreRecord = () => {
        let array = [...placementRecords?.placement_records?.record];
        array.push(
            {
                year: "",
                description: "",
                logo: []
            }
        );
        setPlacementRecords({
            ...placementRecords,
            placement_records: {
                ...placementRecords.placement_records,
                record: [...array]
            }
        })
    }
    
    const placementRecord = (e) => {
        let localArray = [];
        let array = placementRecords.placement_records.record;
        localArray = array.map((element, i) => { 
            return {...element, 
            ...(e.target.name === `year_${i}` && {year: e.target.value}),
            ...(e.target.name === `description_${i}` && {description: e.target.value})
            }
        });
        setPlacementRecords({
            ...placementRecords,
            placement_records: {
                ...placementRecords.placement_records,
                record: localArray
            }
        })
    }

    const removeLogo = (index, i) => {
        let localArray = [];
        let newArray = [];
        let array = [...placementRecords.placement_records.record[index]?.logo];
        localArray = [...placementRecords.placement_records.record];
        array.splice(i, 1);
        newArray = localArray.map((element, ind) => {
            return {...element, 
                ...(index === ind && {
                logo: [...array]
            })}
        })
        setPlacementRecords({
            ...placementRecords,
            placement_records: {
                ...placementRecords.placement_records,
                record: [...newArray]
            }
        })
    }

    return (
        <>
        <div style={{
            marginTop: '3%'
        }}>
            <div className={classes.topHeading}>
                <div></div>
                <Typography component="h2" variant="h2" 
                    className={classes.topHeader}>Details</Typography>
                <div>
                    <Button sx={{
                        backgroundColor: 'var(--primary-color)',
                        width: '130px'
                    }} variant="contained" onClick={saveAndNext} disabled={btnLoader}>
                        {btnLoader ? <LoaderIcon />  : 
                        ((universityById?.placement_records_check && !isEdit) ? 'Next' : 
                        'Save & Next')}
                    </Button>
                    {universityById?.placement_records_check &&
                    <Button variant="outlined" 
                    onClick={() => setIsEdit(!isEdit)}
                    sx={{
                        color: 'var(--primary-color)',
                        marginLeft: '8px'
                        }}>
                        {isEdit ? 'Cancel' : 'Edit'}
                    </Button>}
                </div>
            </div>
            <div className={classes.formIconsBox}>
                <div className={classes.iconBox}>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine}></div>
                        <div className={classes.iconBox2} style={{left: 72}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    }}>
                                <BorderColorIcon fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText}>
                                Create
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 48}}>
                            <div className={classes.iconStyle} 
                                style={{
                                    color: '#fff', 
                                    fontSize: '24px',
                                    backgroundColor: 'var(--primary-color)'
                                    }}>
                                i
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Information
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} 
                            style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--primary-color)'}}>
                                <CameraAltIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Uploads
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 68}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--primary-color)'}}>
                                <MenuBookIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Courses
                            </Typography>
                        </div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={classes.horizontalLine} style={{backgroundColor: 'var(--primary-color)'}}></div>
                        <div className={classes.iconBox2} style={{left: 24}}>
                            <div className={classes.iconStyle} style={{backgroundColor: 'var(--primary-color)'}}>
                                <BusinessCenterIcon className={classes.icon} fontSize="small" />
                            </div>
                            <Typography component="h2" variant="h2" className={classes.iconText} style={{color: 'var(--primary-color)'}}>
                                Placement Records
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10%',
            }}>
                <div style={{
                    width: '68%',
                    marginRight: '40px'
                }}>
                {placementRecords?.placement_records?.record.map((item, index) => (
                    <div key={index} style={{marginBottom: '12px'}}>
                        <div style={{
                            marginBottom: '2%'
                        }} className="year-shadow">
                            <InputLabel className="input-label-text">Year</InputLabel>
                            <Select 
                                name={`year_${index}`}
                                value={item?.year}
                                onChange={placementRecord}
                                className="year-select-width"
                                sx={{
                                    width: '200px',
                                    height: '62px'
                                }}
                            >
                                {yearRange.map((menu, i) => (
                                    <MenuItem value={menu} key={i}>
                                        {menu}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div style={{
                            width: '100%',
                        }}>
                            <Typography component="h2" variant="h2" className="input-label-text" sx={{
                                marginBottom: '4px'
                            }}>
                                Description
                            </Typography>
                            <div style={{marginBottom: '2%'}}>
                                <TextField name={`description_${index}`} placeholder="Write description here..." 
                                    onChange={placementRecord} 
                                    value={item?.description}
                                    variant="outlined" sx={{
                                    width: '100%',
                                }} multiline rows="8"/>
                            </div>
                        </div>
                        <Typography component="h2" variant="h2" className="input-label-text">
                            Company Logo <span className="optional-text-style">(Optional)</span>
                        </Typography>
                        <div className="placement-rec-images" style={{
                            display: 'flex'
                        }}>
                            <UploadBox
                                name={`logo_box_${index}`}
                                height={'190px'}
                                width={'216px'}
                                value={item?.logo?.length > 0 && 
                                    item?.logo[item?.logo?.lenth -1]}
                                handleUpload={(name, value) => onUploadChange(name, value)} />
                            {item?.logo?.length > 0 &&
                                <div style={{
                                    paddingTop: '8px',
                                    marginLeft: '8px'
                                }} className="placement-logo-box">
                                    {item?.logo?.map((logos, i) => (
                                        <div key={`${index}${i}`} style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <p style={{
                                                width: 'fit-content',
                                                margin: 0
                                            }}>
                                                <span style={{fontWeight: 'bold'}}>
                                                    {i+1}.{" "}
                                                </span>
                                                {logos?.split("-")[3]}
                                            </p>
                                            <Button sx={{
                                                backgroundColor: 'red',
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '50%',
                                                minWidth: '20px',
                                                marginLeft: '4px',
                                                '&:hover': {
                                                    backgroundColor: 'red',
                                                }
                                            }} onClick={() => removeLogo(index, i)}>
                                                <ClearIcon fontSize="small" sx={{
                                                    color: '#fff',
                                                    fontSize: '16px'
                                                }} />
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                ))}
                <Button onClick={addMoreRecord} className="add-more-placement-record" sx={{
                    background: 'var(--primary-color)',
                    textTransform: 'capitalize',
                    color: 'white',
                    "&:hover":{
                        background: 'var(--primary-color)'
                    }
                }}>
                    <AddIcon sx={{marginRight: '4px'}} /> Add More
                </Button>
                </div>
                <div>
                    <Typography component="h2" variant="h2" className="input-label-text">
                        Brochure of the year <span className="optional-text-style">(Optional)</span>
                    </Typography>
                    <UploadBox
                        name={`brochour-upload`}
                        height={'190px'}
                        width={'216px'}
                        value={placementRecords.placement_records.brochure && 
                            placementRecords.placement_records.brochure}
                        handleUpload={(name, value) => onUploadChange(name, value)} />
                </div>
            </div>
        </div>
        </>
    );
};

export default Listing;
