import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Listing from '../../components/listing/Listing';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  IconButton, 
  Typography } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductIcon from '../../assest/images/product_icon.png';
import { toast } from "react-toastify";
import { logout, reset } from "../../redux/auth/authSlice";
import { 
  getUniversities, 
  setUniversityById,
  getUniversityByToken,
  setUniversityByIdData,
  universityStatusUpdate
} from "../../redux/universities/universitySlice";
import { 
  getProductByInstitute,
  setInstituteIdForProduct 
} from "../../redux/instituteProd/instituteProdSlice";
import { 
  getCoaching, 
  getCoachingByToken,
  setCoachingByIdData,
  coachingStatusUpdate
} from "../../redux/coaching/coachingSlice";

let requestBody = {
  id: "",
  from: "block",
  request_status: "blocked"
};

const Approved = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [popfor, setPopFor] = useState("");
  const [isListing, setIsListing] = useState(false);
  const [rejectFormBody, setRejectFormBody] = useState(requestBody);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    universityData,
  } = useSelector((state) => state.university);
  const { type } = useSelector(
    (state) => state.university
  );
  const { 
    isCLoading, 
    isCError, 
    coachingData } = useSelector((state) => state.coaching);
  const colR = ["SNo", "Name of University", "city", "Full Address", "Website Link", "action", "status"];
  const colR2 = ["SNo", "Name of Coaching Center", "city", "Full Address", "Website Link", "product", "action", "status"];

  useEffect(() => {
    let res = null;
    if(type === "University") (res = dispatch(getUniversities({ limit, offset, searchText: "", request_status: "accepted" })));
    if(type === "Coaching") (res = dispatch(getCoaching({ limit, offset, searchText: "", request_status: "accepted" })));
    // res?.then((r) => {
    //   if(r?.payload?.message.toLowerCase() === "unauthorized" || 
    //     r?.payload?.message === "Session Expired"){
    //     toast.error("Session expired, please login again");
    //     localStorage.removeItem("hp_token");
    //     localStorage.removeItem("hp_user");
    //     navigate(routes.LOGIN);
    //   }
    // })
  }, [limit, offset, type]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      let res = null;
      type === "University" ? (res = dispatch(getUniversities({ limit, offset, searchText, request_status: "accepted" }))) :
        (res = dispatch(getCoaching({ limit, offset, searchText, request_status: "accepted" })))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  const getProductList = (id) => {
    let params= {};
    type === "University" ? (params = {limit, offset, university_id: id}) : (params = {limit, offset, coaching_id: id});
    setProductLoader(true)
    dispatch(setInstituteIdForProduct(id))
    const res = dispatch(getProductByInstitute(params));
    res.then((r) => {
      setProductLoader(false);
      if(r.payload.statusCode === 200) {
        navigate(routes.PRODUCT_LIST)
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }

    })
  }

  if (isLoading || isCLoading || productLoader) return <Spinner />;
  if (isError || isCError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const rejectClicked = () => {
    if(type === "University"){
      const res = dispatch(universityStatusUpdate(rejectFormBody));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("University Blocked");
          dispatch(getUniversities({ limit, offset, searchText: "", request_status: "accepted" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }else{
      const res = dispatch(coachingStatusUpdate(rejectFormBody));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("Coaching Blocked");
          dispatch(getCoaching({ limit, offset, searchText: "", request_status: "accepted" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }
  }

  // const addListing = () => {
  //   dispatch(setUniversityByIdData(null));
  //   dispatch(setCoachingByIdData(null));
    
  //   if(type === "University"){
  //     if(!localStorage.getItem("new_institute_token")){
  //       setIsListing(true);
  //     }else{
        
  //       let res = dispatch(getUniversityByToken());
  //       res.then((r) => {
  //         if(r.payload.statusCode === 200){
  //           if(!r?.payload?.data?.information_check){
  //             navigate(routes.INFORMATION);
  //             return;
  //           }
  //           if(!r?.payload?.data?.uploads_check){
  //             navigate(routes.UPLOAD);
  //             return;
  //           }
  //           if(!r?.payload?.data?.courses_offered_check){
  //             navigate(routes.COURSE);
  //             return;
  //           }
  //           if(!r?.payload?.data?.placement_records_check){
  //             navigate(routes.PLACEMENT_RECORD);
  //             return;
  //           }
  //         }else{
  //           if(r?.payload?.message.toLowerCase() === "unauthorized" || 
  //             r?.payload?.message === "Session Expired"){
  //             toast.error("Session expired, please login again");
  //             dispatch(logout());
  //             dispatch(reset());
  //             navigate(routes.LOGIN);
  //             return;
  //           }
  //           toast.error(r?.payload?.message);
  //         }
  //       })
  //     }
  //   }else{
  //     if(!localStorage.getItem("new_coaching_token")){
  //       setIsListing(true)
  //     }else{
  //       let res = dispatch(getCoachingByToken());
  //       res.then((r) => {
  //         if(r.payload.statusCode === 200){
  //           if(!r?.payload?.data?.information_check){
  //             navigate(routes.COACHING_INFORMATION);
  //             return;
  //           }
  //           if(!r?.payload?.data?.uploads_check){
  //             navigate(routes.COACHING_UPLOAD);
  //             return;
  //           }
  //           if(!r?.payload?.data?.faculties_details_check){
  //             navigate(routes.COACHING_COURSE);
  //             return;
  //           }
  //         }else{
  //           if(r?.payload?.message.toLowerCase() === "unauthorized" || 
  //             r?.payload?.message === "Session Expired"){
  //             toast.error("Session expired, please login again");
  //             dispatch(logout());
  //             dispatch(reset());
  //             navigate(routes.LOGIN);
  //             return;
  //           }
  //           toast.error(r?.payload?.message);
  //         }
  //       })
  //     }
  //   }
  // }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDialogOpen}
        onClose={handleClose}
      >
        <div>
          {popfor === "block" &&
            <Typography
              variant="p"
              component="p"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'
              }}
            >
              Are you sure you want to<br/> block the {type} ?
            </Typography>
          }
          {popfor === "edit" &&
            <Typography
              variant="p"
              component="p"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '28px 0 48px'
              }}
            >
              What would you like to edit<br/> basic details or other details ?
            </Typography>
          }
          {popfor === "block" &&
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 16px 28px 16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ textTransform: 'capitalize' }}
                onClick={() => {setIsDialogOpen(false)}} 
              >
                Cancel
              </Button>
              <Button
                onClick={() => {rejectClicked()}}
                variant="contained"
                sx={{
                  width: '84px',
                  marginRight: '12px',
                  textTransform: 'capitalize',
                  backgroundColor: 'var(--primary-color)'
                }}
              >
                Yes
              </Button>
            </div>
          }
          {popfor === "edit" &&
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                padding: '0px 4px 28px 4px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ textTransform: 'capitalize' }}
                onClick={() => { navigate(routes.U_EDIT) }}
              >
                Edit Other Details
              </Button>
              <Button
                onClick={() => { navigate(routes.EDIT_UNIVERSITY_BASIC) }}
                variant="contained"
                sx={{
                  marginRight: '12px',
                  textTransform: 'capitalize',
                  backgroundColor: 'var(--primary-color)'
                }}
              >
                Edit Basic Details
              </Button>
            </div>
          }
        </div>
      </Dialog>
      {isListing ?
        <div>
          <Listing 
            from={type}
            back={() => {setIsListing(false)}}
          />
        </div>
      :
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="meal"
        />
        <div 
          style={{
            display: 'flex', 
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginTop: '12px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {navigate(routes.ADD_UNIVERSITY)}}
            sx={{
              backgroundColor: '#F69220',
              textTransform: 'capitalize',
              borderRadius: '8px'
            }}
          >
          <ControlPointIcon sx={{marginRight: '4px'}} />
            Add Listing
          </Button>
        </div>
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <MealTable
          where="recipes"
          columns={type === "University" ? colR : colR2}
          rows={type === "University" ? (universityData && universityData?.map((item) => {
            const res = {
              ...item,
              // product:(
              //   <div>
              //     <IconButton onClick={() => getProductList(item._id)}
              //       color="inherit"
              //       edge="start"
              //       sx={{
              //         display: 'flex',
              //         flexDirection: 'column'
              //       }}
              //     >
              //       <img src={ProductIcon} alt="Product Icon" width="20" />
              //       <Typography component="p" variant="p" 
              //         sx={{
              //           color: 'var(--primary-color)',
              //           fontSize: '18px',
              //           fontWeight: 'bold',
              //           marginTop: '6px'
              //           }}>
              //         Products
              //       </Typography>
              //     </IconButton>
              //   </div>
              // ),
              action: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '8px'
                    }}
                    onClick={() => {
                      setPopFor("block");
                      setIsDialogOpen(true);
                      setRejectFormBody({...rejectFormBody, id: item._id})
                    }}
                  >
                    <DoDisturbIcon sx={{color: '#EF0000'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#EF0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Block
                    </Typography>
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => { dispatch(setUniversityById(item._id)) }}
                  >
                    <Link
                      to={routes.DETAILS}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography 
                        component="p" 
                        variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginRight: '8px'
                        }}
                      >
                        View
                      </Typography>
                    </Link>
                  </IconButton>
                  <IconButton
                    edge="start"
                    color="inherit"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      setPopFor("edit");
                      dispatch(setUniversityById(item._id));
                      setIsDialogOpen(true);
                    }}
                  >
                    {/* <Link
                      to={routes.U_EDIT}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    > */}
                      <BorderColorIcon sx={{color: '#ECAB2D'}} />
                      <Typography
                        variant="p"
                        component="p"
                        sx={{
                          color: '#ECAB2D',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginTop: '6px'
                        }}
                      >
                        Edit
                      </Typography>
                    {/* </Link> */}
                  </IconButton>
                </div>
              ),
              status: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                  >
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold'
                        }}>
                      Approved
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })) :
          (coachingData && coachingData?.map((item) => {
            const res = {
              ...item,
              product:(
                <div>
                  <IconButton onClick={() => getProductList(item._id)}
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <img src={ProductIcon} alt="Product Icon" width="20" />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: 'var(--primary-color)',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Products
                    </Typography>
                  </IconButton>
                </div>
              ),
              action: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '8px'
                    }}
                    onClick={() => {
                      setIsDialogOpen(true);
                      setRejectFormBody({...rejectFormBody, id: item._id})
                    }}
                  >
                    <DoDisturbIcon sx={{color: '#EF0000'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#EF0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Block
                    </Typography>
                  </IconButton>
                  <IconButton onClick={() => {
                    dispatch(setUniversityById(item._id));
                  }}
                    color="inherit"
                    edge="start"
                  >
                    <Link to={routes.DETAILS} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </Link>
                  </IconButton>
                </div>
              ),
              status: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                  >
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold'
                        }}>
                      Approved
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          }))
        }
        />
      </div>
      }
    </>
  );
};

export default Approved;
