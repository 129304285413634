import React, { useEffect, useState } from "react";
import JoditEditor from 'jodit-react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import routes from "../../react_routes_enum";
import { validateUrl } from "../../utils/validateUrl";
import { useDispatch, useSelector } from "react-redux";
import UploadBox from '../../components/UploadBox/UploadBox';
import { getUniversities } from "../../redux/universities/universitySlice";
import { getCoaching } from "../../redux/coaching/coachingSlice";
import { addBlog, updateBlog } from "../../redux/students/studentsSlice";
import LoaderIcon from "../../components/loader/Loader";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { 
    InputLabel,
    Button,
    Select,
    MenuItem,
    TextField } from "@mui/material";
import "./blogs.css";

const data = {
    type: "university",
    typeId: "",
    title: "",
    sub_title: "",
    category: "",
    description: "",
    images: [],
    embedded_videos: [],
    tags: [],
    meta: {
        title: "",
        description: "",
        keywords: []
    }
}
let array = [];
let array2 = [];
const BlogsForm = () => {
    const editor = React.useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [btnLoader, setBtnLoader] = useState(false);
    const [formData, setFormData] = useState(data);
    const [tagsData, setTagsData] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");
    // const [imageArray, setImageArray] = useState([""]);
    const [universityList, setUniversityList] = useState([]);
    const [coachingList, setCoachingList] = useState([]);

    const { blogById, isNewBlog } = useSelector((state) => state.students);

    useEffect(() => { 
        if(blogById){
            array = [...blogById.tags];
            array2 = [...blogById.meta.keywords];
            setFormData({
                ...blogById,
                typeId: blogById?.university_id ? blogById?.university_id : blogById?.coaching_id,
                type: blogById?.university_id ? 'university' : 'coaching'
            })
            // setImageArray(blogById?.images)
        }
    },[blogById]);

    useEffect(() => {
        const res = dispatch(getUniversities({limit: 10000, offset: 0, request_status: "accepted" }));
        res?.then((r) => {
            r?.payload.statusCode === 200 && 
                setUniversityList(r?.payload?.data?.result)
        });
    },[dispatch])

    useEffect(() => {
        const res = dispatch(getCoaching({ limit: 10000, offset: 0, request_status: "accepted" }));
        res?.then((r) => {
            r?.payload.statusCode === 200 && 
            setCoachingList(r?.payload?.data?.result)
        })
    }, [dispatch])

    const handleChange = (e) => {
        e.target.name === "title" && setFormData({...formData, title: e.target.value});
        e.target.name === "sub_title" && setFormData({...formData, sub_title: e.target.value});
        e.target.name === "meta_title" && setFormData({...formData, meta: 
            {...formData.meta, title:e.target.value}})
        e.target.name === "meta_desc" && setFormData({...formData, meta: 
            {...formData.meta, description: e.target.value}})
        e.target.name === "coaching" && setFormData({...formData, typeId: e.target.value})
        e.target.name === "university" && setFormData({...formData, typeId: e.target.value})
        e.target.name === "type" && setFormData({...formData, type: e.target.value, typeId: ""});
        e.target.name === "category" && setFormData({...formData, category: e.target.value})
        e.target.name === "description" && 
            setFormData({...formData, description: e.target.value})
        e.target.name === "embedded_video" && 
            setFormData({...formData, embedded_videos: [e.target.value]})
        if(e.target.name === "tags"){
            setTagsData(e.target.value);
            if(e.target.value.split(",").length > 1){
                array.push(e.target.value.split(",")[0]);
                setFormData({...formData, tags: [...array]})
                setTagsData("");
            }
        }
        if(e.target.name === "meta_keywords"){
            setMetaKeywords(e.target.value);
            if(e.target.value.split(",").length > 1){
                array2.push(e.target.value.split(",")[0]);
                setFormData({...formData, meta: {...formData.meta, keywords: [...array2]}})
                setMetaKeywords("");
            }
        }
    }

    const valideForm = () => {
        let error = {message: '', status: true}
        if(!formData?.title || formData?.title === ""){
            error.message = "Title Required";
            error.status = false
            return error;
        }
        if(!formData?.sub_title || formData?.sub_title === ""){
            error.message = "Title Required";
            error.status = false
            return error;
        }
        if(!formData?.description || formData?.description === ""){
            error.message = "Description Required";
            error.status = false
            return error;
        }
        if(!formData?.tags || formData?.tags.length === 0){
            error.message = "Atleast 1 Tag Required";
            error.status = false
            return error;
        }
        if(!formData?.category || formData?.category === ""){
            error.message = "Category Required";
            error.status = false
            return error;
        }
        if(!formData?.meta?.title || formData?.meta?.title === ""){
            error.message = "Meta Title Required";
            error.status = false
            return error;
        }
        if(!formData?.meta?.description || formData?.meta?.description === ""){
            error.message = "Meta Description Required";
            error.status = false
            return error;
        }
        if(!formData?.meta?.keywords || formData?.meta?.keywords.length === 0){
            error.message = "Atleast 1 Meta Keyword Required";
            error.status = false
            return error;
        }
        if(formData?.embedded_videos?.length > 0 && formData?.embedded_videos[0] !== ""){
            console.log("tes", formData?.embedded_videos)
            if(!validateUrl(formData?.embedded_videos[0])){
                error.message = "Video link is not valid";
                error.status = false
                return error;
            }
        }
        if(!formData?.type || formData?.type === ""){
            error.message = "Please blog type";
            error.status = false
            return error;
        }
        if(!formData?.typeId || formData?.typeId === ""){
            error.message = "Please select a coaching or university";
            error.status = false;
            return error;
        }
        if(!formData?.images || formData?.images?.length === 0){
            error.message = "Atleast 1 Image Required";
            error.status = false
            return error;
        }
        return error;
    }

    const submitBlog = () => {
        if(!valideForm().status){
            toast.error(valideForm().message);
            return;
        }

        setBtnLoader(true);
        if(isNewBlog){
            const res = dispatch(addBlog(formData));
            res?.then((r) => {
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    toast.success("Blog Added Successfully");
                    navigate(routes.BLOGS);
                    return;
                }
                if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                    r?.payload?.message === "Session Expired"){
                    toast.error("Session expired, please login again");
                    localStorage.removeItem("hp_token");
                    localStorage.removeItem("hp_user");
                    navigate(routes.LOGIN);
                    return;
                }
                toast.error(r?.payload?.message);
            })
            return;
        }
        else{
            let {
                __v,
                _id,
                type,
                typeId,
                university_id,
                coaching_id,
                created_at,
                updated_at,
                ...rest
            } = formData;
            const res = dispatch(updateBlog({id: _id, body: rest}));
            res?.then((r) => {
                setBtnLoader(false);
                if(r?.payload?.statusCode === 200){
                    toast.success("Blog Updated Successfully");
                    navigate(routes.BLOGS);
                    return;
                }
                if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                    r?.payload?.message === "Session Expired"){
                    toast.error("Session expired, please login again");
                    localStorage.removeItem("hp_token");
                    localStorage.removeItem("hp_user");
                    navigate(routes.LOGIN);
                    return;
                }
                toast.error(r?.payload?.message);
            })
        }
    }

    const handleImageUploads = (name, value) => {
        let arr = [...formData.images];
        arr[parseInt(name.split("_")[3])] = value;
        setFormData({...formData, images: [...arr]})
        // setImageArray([...arr]);
    }

    const addMore = () => {
        let array = [...formData.images];
        if(array.length > 4){
            toast.error("Maximum 5 images are allowed")
            return;
        }
        array.push("");
        setFormData({...formData, images: [...array]});
    }

    return (
        <section className="blog-box" style={{alignItems: 'flex-start'}}>
            <div style={{width: '950px'}}>
                <div style={{
                    width: '100%',
                    marginBottom: '20px'
                }}>
                    <InputLabel className="input-label-text">
                        Title
                    </InputLabel>
                    <TextField 
                        placeholder="" 
                        name="title" 
                        type="text" 
                        variant="outlined" 
                        value={formData.title}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={handleChange} 
                        required/>
                </div>
                <div style={{
                    width: '100%',
                    marginBottom: '20px'
                }}>
                    <InputLabel className="input-label-text">
                        Sub Title
                    </InputLabel>
                    <TextField 
                        placeholder="" 
                        name="sub_title" 
                        type="text" 
                        variant="outlined" 
                        value={formData.sub_title}
                        sx={{
                            width: '100%',
                            height: '62px'
                        }} 
                        onChange={handleChange} 
                        required/>
                </div>
                <div style={{
                    marginBottom: '20px'
                }}>
                    <InputLabel className="input-label-text">Description</InputLabel>
                    <JoditEditor 
                        ref={editor}
                        value={formData.description}
                        onChange={(e) => handleChange({
                            target:{name: 'description', value: e}})}
                    />
                </div>
                <div className="blog-tag-box">
                    <div className="blog-tag-box-inp">
                        <InputLabel className="input-label-text">
                            Tags <span style={{
                                    fontSize: '12px',
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>(Enter (,) to add tag)</span>
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="tags" 
                            type="text" 
                            variant="outlined" 
                            value={tagsData}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                        {formData?.tags && 
                            formData?.tags?.map((item, index) => (
                            index + 1 === formData?.tags.length ? 
                            `${item}` : `${item}, `))}
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Category Taxanomies
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="category" 
                            type="text" 
                            variant="outlined" 
                            value={formData.category}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Embedded Videos <span style={{
                                    fontSize: '12px',
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>(Optional)</span>
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="embedded_video" 
                            type="text" 
                            variant="outlined" 
                            value={formData.embedded_videos}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} required/>
                    </div>
                </div>
                <div className="blog-tag-box">
                    <div className="blog-tag-box-inp">
                        <InputLabel className="input-label-text">
                            Meta Title
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="meta_title" 
                            type="text" 
                            variant="outlined" 
                            value={formData?.meta?.title}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} onChange={handleChange} required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Meta Descriptions
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="meta_desc" 
                            type="text" 
                            variant="outlined" 
                            value={formData?.meta?.description}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                    </div>
                    <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                        <InputLabel className="input-label-text">
                            Meta Keywords <span style={{
                                    fontSize: '12px',
                                    color: 'rgb(0, 0, 0, 0.5)'
                                }}>(Enter (,) to add meta tag)</span>
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            name="meta_keywords" 
                            type="text" 
                            variant="outlined" 
                            value={metaKeywords ? metaKeywords : ''}
                            sx={{
                                width: '100%',
                                height: '62px'
                            }} 
                            onChange={handleChange} 
                            required/>
                        {formData?.meta?.keywords && 
                            formData?.meta?.keywords?.map((item, index) => (
                            index + 1 === formData?.meta?.keywords.length ? 
                            `${item}` : `${item}, `))}
                    </div>
                </div>
                {isNewBlog &&
                    <>
                        <InputLabel className="input-label-text">
                            Select blog type
                        </InputLabel>
                        <div className="four-input-box margin-bot-change" 
                            style={{width: '250px', marginBottom: '12px'}}>
                            <RadioGroup
                                row
                                name="type"
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={(formData?.type?.toLowerCase() === "university") ? 
                                    'University' : 'Coaching'}
                                onChange={(e) => handleChange(e)}
                            >
                                <FormControlLabel 
                                    value="university" 
                                    sx={{textTransform: 'capitalize'}}
                                    checked={formData?.type?.toLowerCase() === "university"}
                                    control={<Radio sx={{
                                        color: 'var(--secondary-color)',
                                        '&.Mui-checked': {
                                            color: 'var(--secondary-color)',
                                            },
                                    }} />} 
                                    label="university" />
                                <FormControlLabel 
                                    value="coaching" 
                                    checked={formData?.type?.toLowerCase() === "coaching"}
                                    sx={{textTransform: 'capitalize'}}
                                    control={<Radio sx={{
                                        color: 'var(--secondary-color)',
                                        '&.Mui-checked': {
                                            color: 'var(--secondary-color)',
                                            },
                                    }} />} 
                                    label="coaching" />
                            </RadioGroup>
                        </div>
                        <div className="blog-tag-box">
                            <div className="blog-tag-box-inp">
                                <InputLabel className="input-label-text">
                                    Select University/College
                                </InputLabel>
                                <Select
                                    required
                                    name="university"
                                    disabled={formData?.type?.toLowerCase() === "coaching"}
                                    value={formData?.type?.toLowerCase() === "university" ? 
                                        formData?.typeId : ""}
                                    onChange={handleChange}
                                    placeholder="course"
                                    autoWidth
                                    sx={{
                                        width: '100%',
                                        height: '56px',
                                        position: 'relative',
                                        marginRight: '12px',
                                        border: 'none'
                                    }}>
                                    {universityList?.map((item, index) => (
                                        <MenuItem value={item?._id} key={index}>
                                            {item?.university_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="blog-tag-box-inp" style={{marginLeft: '24px'}}>
                                <InputLabel className="input-label-text">
                                    Select Coaching
                                </InputLabel>
                                <Select
                                    required
                                    name="coaching"
                                    value={formData?.type === "coaching" ? 
                                        formData?.typeId : ""}
                                    disabled={formData?.type === "university"}
                                    onChange={handleChange}
                                    placeholder="course"
                                    autoWidth
                                    sx={{
                                        width: '100%',
                                        height: '56px',
                                        position: 'relative',
                                        marginRight: '12px',
                                        border: 'none'
                                    }}>
                                    {coachingList?.map((item, index) => (
                                        <MenuItem value={item?._id} key={index}>
                                            {item?.coaching_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </>
                }
                <div>
                    <InputLabel className="input-label-text">
                        Upload Images
                    </InputLabel>
                    <div style={{
                        display: 'inline-block'
                    }}>
                        {formData?.images?.map((item, index) => (
                            <div style={{
                                display: 'inline-block',
                                marginRight: '16px',
                                marginBottom: '8px'
                                }} key={index}>
                                <UploadBox
                                    name={`add_on_img_${index}`}
                                    height={'166px'}
                                    width={'216px'}
                                    value={item ? item : ""}
                                    handleUpload={
                                        (name, value) => handleImageUploads(name, value, index)
                                    }
                                />
                            </div>
                        ))}
                        <Button style={{
                                backgroundColor: 'var(--primary-color)',
                            }} onClick={addMore} className="add-more-button">
                            <AddIcon /> Add More
                        </Button>
                    </div>
                </div>
            </div>
            <Button disabled={btnLoader} onClick={submitBlog}
                style={{
                    width: '100px',
                    background: btnLoader ? 'rgba(0, 0, 0, 0.3)' : '#F69220',
                    color: '#FFFFFF',
                }}>
                {btnLoader ? <LoaderIcon /> : `${isNewBlog ? "Submit" : "Update"}`}
            </Button>
        </section>
    )
}

export default BlogsForm;