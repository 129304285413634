import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Chip from '@mui/material/Chip';
import Dialog from "@mui/material/Dialog";
import routes from "../../react_routes_enum";
import DownloadIcon from '@mui/icons-material/Download';
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import UserTable from "../../components/BasicTable/BasicTable";
import Spinner from "../../components/Spinner/Spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from "../../components/Pagination/Pagination";
import { getCourseOrders } from "../../redux/students/studentsSlice";
import { TextField, InputLabel, IconButton, Typography } from "@mui/material";

const CourseOrders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);
    const [orderData, setOrderData] = useState({});
    const [userListView, setUserListView] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const { 
        isError,
        message,
        isLoading,
        totalCount,
        courseOrdersData } = useSelector((state) => state.students)
  
    const columns = [
        "SNo",
        "Order Date",
        "Order Id",
        "Payment Id",
        "Status",
        "Action"
    ];

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        const res = dispatch(getCourseOrders({limit, offset}));
        res?.then((r) => {
            if(r.payload.message === "unauthorized" || 
                r.payload.message === "Unauthorized" ||
                r.payload.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
            }
        })
    }, [limit, offset]);

    if (isLoading) return <Spinner />;

    if (isError) return <h1 style={{ color: "red" }}>{message}</h1>;
  
    return (
        <div style={{
        marginTop: '5%'
        }}>
        <Dialog maxWidth="sm" open={isDialogOpen} onClose={handleClose} fullWidth>
            <DialogContent>
                <h3 style={{
                    fontSize: '24px',
                    textAlign: 'center',
                    marginBottom: '0px'
                }}>
                    Student & Course Detail
                </h3>
                <div style={{
                    height: '20px',
                    width: '100%'
                }}></div>
                <div className="order-customer">
                    {orderData?.name &&
                        <div>
                            <span>Name : </span>
                            <span>{orderData?.name}</span>
                        </div>
                    }
                    {orderData?.email &&
                        <div>
                            <span>Email : </span>
                            <span>{orderData?.email}</span>
                        </div>
                    }
                    {orderData?.phone &&
                        <div>
                            <span>Phone : </span>
                            <span>{orderData?.phone}</span>
                        </div>
                    }
                    {orderData?.address &&
                        <div>
                            <span>Address : </span>
                            <span>{orderData?.address}</span>
                        </div>
                    }
                </div>
                <div>
                    {orderData?.referal_id &&
                        <div>
                            <h3 
                                style={{
                                    fontSize: '20px',
                                    marginTop: '8px',
                                    marginBottom: '4px',
                                    color: 'var(--secondary-color)'
                                }}
                            >
                                Referal Detail : 
                            </h3>
                            <div className="order-course">
                                <div>
                                    <span>Name : </span>
                                    <span>{orderData?.referal_id?.name}</span>
                                </div>
                                <div>
                                    <span>Referal Code : </span>
                                    <span>{orderData?.referal_id?.username}</span>
                                </div>
                                <div>
                                    <span>Email : </span>
                                    <span>{orderData?.referal_id?.email}</span>
                                </div>
                            </div>
                        </div>
                    }
                    <h3 
                        style={{
                            fontSize: '20px',
                            marginTop: '8px',
                            marginBottom: '4px',
                            color: 'var(--secondary-color)'
                        }}
                    >
                        Course details : 
                    </h3>
                    {orderData?.courses?.map((item, index) => (
                        <div key={index} className="order-course">
                            <div>
                                <span>University Name : </span>
                                <span>{item?.university_name}</span>
                            </div>
                            <div>
                                <span>City : </span>
                                <span>{item?.university_name}</span>
                            </div>
                            <div className="course-heading">
                                <h3>Course Name</h3>
                                <h3>Course Duration</h3>
                                <h3>Course Fees</h3>
                            </div>
                            {item?.item?.map((it, ind) => (
                                <div
                                    key={`${index}${ind}`}
                                    className="course-body"
                                >
                                    <p>{it.course}</p>
                                    <p>{it.duration}</p>
                                    <p>{it.fees}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
        {userListView && (
            <>
            {/* <SearchTextField
                searchText={searchText}
                handleSearchText={handleSearchText}
                fromWhere="subscribedUsers"
            /> */}
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
            <UserTable
                columns={columns}
                rows={
                    courseOrdersData &&
                    courseOrdersData?.map((item) => {
                    const res = {
                        ...item,
                        Status: (
                            <>
                                {item?.status === "in_progress" &&
                                    <Chip
                                        color="warning"
                                        label="In Progress"
                                    />
                                }
                                {item?.status === "completed" &&
                                    <Chip
                                        color="success"
                                        label="Completed"
                                    />
                                }
                                {item?.status === "failed" &&
                                    <Chip label="Failed" color="error" />
                                }
                            </>
                        ),
                        Action: (
                            <IconButton
                                color="inherit"
                                edge="start"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                onClick={() => {
                                    setIsDialogOpen(true)
                                    setOrderData(item)
                                }}
                            >
                                <VisibilityIcon sx={{color: '#3C1CDE'}} />
                                <Typography
                                    component="p"
                                    variant="p"
                                    sx={{
                                        color: '#3C1CDE',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    View
                                </Typography>
                            </IconButton>
                        )
                    };
                    return res;
                })
                }
            />
            </>
        )}
        </div>
    );
};

export default CourseOrders;
