export const removeEmptyKeys = (obj) => {
    // let filteredObj = {};
    // for (const key in obj){
    //     if (obj.hasOwnProperty(key) && obj[key] !== null && 
    //         obj[key] !== undefined && obj[key] !== '') {
    //         filteredObj[key] = obj[key];
    //     }
    // }
    // return filteredObj;
    if (Array.isArray(obj)) {
        return obj
            .map(removeEmptyKeys) // Process each item in the array
            .filter(item => item && Object.keys(item).length > 0); // Remove empty objects from the array
    } else if (obj && typeof obj === "object") {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            const cleanedValue = removeEmptyKeys(value); // Recursively clean the value
            if (cleanedValue !== null && cleanedValue !== "" && cleanedValue !== undefined && 
                (!(Array.isArray(cleanedValue) || typeof cleanedValue === "object") || Object.keys(cleanedValue).length > 0)) {
                acc[key] = cleanedValue; // Add to the accumulator if not empty
            }
            return acc;
        }, {});
    }
    return obj;
}