import LoaderIcon from "../../components/loader/Loader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  IconButton, 
  Typography } from "@mui/material";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import PartnerTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import routes from "../../react_routes_enum";
import { 
  getPartners, 
  setPartnerById,
  partnerStatusUpdate
} from "../../redux/partner/partnerSlice";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner/Spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';

let requestBody = {
  id: "",
  request_status: "rejected",
  reject_reason: "",
  from: "reject",
};

const PartnerRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [requestFormBody, setRequestFormBody] = useState(requestBody);
  const colR = ["SNo", "Name", "Username", "Email ID", "Address", "ID_Proof", "actions"];

  const { 
    isLoading,
    isError, 
    message,
    totalCount,
    searchText,
    partnersData } = useSelector(
    (state) => state.partner
  );

  useEffect(() => {
    const res = dispatch(getPartners({ limit, offset, searchText, request_status: "accepted" }));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      const res = dispatch(getPartners({ limit, offset, searchText, request_status: "accepted" }))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoader(true);
    const res = dispatch(partnerStatusUpdate(requestFormBody));
    res.then((r) => {
      setBtnLoader(false);
      if(r.payload.statusCode === 200){
        toast.success("Partner Request Rejected");
        dispatch(getPartners({ limit, offset, searchText: "", request_status: "pending" }));
        setIsDialogOpen(false);
      }else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  const handleClose = () => setIsDialogOpen(false)
 
  if (isLoading ) return <Spinner />;
  if (isError ) return <h1>{message}</h1>;
  
  return (
    <>
      <Dialog maxWidth="md" open={isDialogOpen} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit} style={{padding: '0 20% 2% 20%'}}>
          <DialogContent>
            <Typography component="h2" variant="h2" 
              sx={{
                color: '#D60000',
                textAlign: 'center',
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '24px 0 48px'}}>
              Rejected
            </Typography>
            <div style={{
              width: '100%',
              border: '1px solid #000000',
              height: '290px'
            }} className="reject-buttons">
              <TextField 
                fullWidth 
                placeholder={"Write a reason for rejection"} 
                variant="outlined"
                multiline
                rows={7}
                sx={{border: 'none'}}
                required
                onChange={(e) => 
                  setRequestFormBody({...requestFormBody, reject_reason: e.target.value})
                }/>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '36px 12px 0 0'
              }}>
                <Button 
                  variant="contained" 
                  type="submit" 
                  disabled={btnLoader}
                  sx={{
                    backgroundColor: btnLoader ? 'var(--disable-color)' : '#07AB35',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    width: '140px',
                    height: '44px',
                    marginLeft: '12px',
                    borderRadius: '8px'
                    }}>
                  {btnLoader ? <LoaderIcon /> :'Submit'}
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="partner"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <PartnerTable
          columns={colR}
          where="recipes"
          rows={partnersData && partnersData?.map((item) => {
            const res = {
              ...item,
              ID_Proof: (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <a
                      href={item?.id_proof} 
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      target="_blank"
                    >
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </a>
                  </IconButton>
                </div>
              ),
              // status: (
              //   <div style={{
              //     display: 'flex',
              //     alignItems: 'center'
              //   }}>
              //     <IconButton
              //       color="inherit"
              //       edge="start"
              //       sx={{
              //         display: 'flex',
              //         flexDirection: 'column',
              //         margin: '0 8px'
              //       }}
              //     >
              //       <Typography component="p" variant="p" 
              //         sx={{
              //           color: '#07AB35',
              //           fontSize: '18px',
              //           fontWeight: 'bold'
              //           }}>
              //         Approved
              //       </Typography>
              //     </IconButton>
              //   </div>
              // ),
              actions: (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  {/* <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      dispatch(setPartnerById(item._id));
                    }}
                  >
                    <Link to={routes.PARTNER_DETAILS} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </Link>
                  </IconButton> */}
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '8px'
                    }}
                    onClick={() => {
                      setIsDialogOpen(true);
                      setRequestFormBody({...requestFormBody, id: item._id})
                    }}
                  >
                    <DoDisturbIcon sx={{color: '#EF0000'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#EF0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Reject
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })}
        />
      </div>
    </>
  );
};

export default PartnerRequest;
