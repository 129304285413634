import JoditEditor from 'jodit-react';
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { validateUrl } from "../../utils/validateUrl";
import { useDispatch, useSelector } from "react-redux";
import LoaderIcon from "../../components/loader/Loader";
import countryListData from "../../data/countryList.json";
import UploadBox from "../../components/UploadBox/UploadBox";
import { removeEmptyKeys } from '../../utils/removeEmptyKeys';
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import { Typography, Select, InputLabel, MenuItem, TextField, Button } from "@mui/material";
import { addNewCourse, updateCourse } from "../../redux/students/studentsSlice";

const useStyles = makeStyles( theme => ({
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    topHeader: {
        marginBottom: 24,
        fontSize: '34px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    marginBot24: {
        marginBottom: '24px'
    },
}))

let formInfo = {
    name: "",
    jobs: "",
    logo: "",
    salary: "",
    syllabus: "",
    category: "",
    brochure: "",
    avg_package: "",
    description: "",
    avg_duration: "",
    sub_description: ""
}

const CourseForm = (props) => {
    const editor = useRef(null);
    const classes = useStyles();
    const naviagte = useNavigate();
    const dispatch = useDispatch();

    const [btnLoader, setBtnLoader] = useState(false);
    const [universityCat, setUniversityCat] = useState([]);
    const [formInformation, setFormInformation] = useState(formInfo);

    const { isLoading, universityId } = useSelector((state) => state.university);
    const { availableCourseById } = useSelector((state) => state.students)

    useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.data){
                r?.payload?.data?.map((item) => {
                    if(item.configuration_type === "university_category"){
                        setUniversityCat(item?.data)
                    }
                })
            }
        })
    }, [])

    useEffect(() => {
        if(props.type === "Edit"){
            availableCourseById && setFormInformation({
                name: availableCourseById?.name || null,
                jobs: availableCourseById?.jobs || null,
                logo: availableCourseById?.logo || null,
                salary: availableCourseById?.salary || null,
                syllabus: availableCourseById?.syllabus || null,
                category: availableCourseById?.category || null,
                brochure: availableCourseById?.brochure || null,
                description: availableCourseById?.description || null,
                avg_package: availableCourseById?.avg_package || null,
                avg_duration: availableCourseById?.avg_duration || null,
                sub_description: availableCourseById?.sub_description || null
            });
        }
    }, [props.type])

    const handleChange = (event) => {
        event.target.name === 'name' && setFormInformation({...formInformation, name: event.target.value})
        event.target.name === "category" && setFormInformation({...formInformation, category: event.target.value });
        event.target.name === "avg_package" && setFormInformation({...formInformation, avg_package: event.target.value });
        event.target.name === "avg_duration" && setFormInformation({...formInformation, avg_duration: event.target.value });
        event.target.name === "sub_description" && setFormInformation({...formInformation, sub_description: event.target.value });
    };

    const textEditor = (text, name) => {
        name === "description" && setFormInformation({...formInformation, description: text});
        name === "syllabus" && setFormInformation({...formInformation, syllabus: text});
        name === "jobs" && setFormInformation({...formInformation, jobs: text});
        name === "salary" && setFormInformation({...formInformation, salary: text});
    }

    const handleImageUploads = (name, value) => {
        name === "logo-upload" && setFormInformation({...formInformation, logo: value});
        name === "brochour-upload" && setFormInformation({...formInformation, brochure: value});
    }

    const validate = () => {
        const status = {isValid: true, message: ""};
        if(!formInformation?.name || formInformation?.name === ""){
            status.isValid = false;
            status.message = "Course Name Required";
            return status;
        }
        if(!formInformation?.category || formInformation?.category === ""){
            status.isValid = false;
            status.message = "Category Required";
            return status;
        }
        if(!formInformation?.avg_package || formInformation?.avg_package === ""){
            status.isValid = false;
            status.message = "Average Package Required";
            return status;
        }
        if(!formInformation?.avg_duration || formInformation?.avg_duration === ""){
            status.isValid = false;
            status.message = "Average Duration Required";
            return status;
        }
        if(!formInformation?.sub_description || formInformation?.sub_description === ""){
            status.isValid = false;
            status.message = "Sub Description Required";
            return status;
        }
        return status;
    }

    const submit = () => {
        const status = validate();
        if(status.isValid){
            const body = removeEmptyKeys(formInformation);
            setBtnLoader(true);

            const res = props.type === "Add" ? 
                dispatch(addNewCourse(body)) : 
                dispatch(updateCourse({
                    body,
                    id: availableCourseById._id,
                }))
            res?.then((r) => {
                setBtnLoader(false);
                if(r.payload.statusCode === 200){
                    toast.success(r.payload.message);
                    naviagte(routes.AVAILABLE_COURSES);
                    return;
                }
                toast.error(r.payload.message);
            })
            return;
        }
        toast.error(status.message);
    }

    return (
        <div>
            <Typography
                variant="h2"
                component="h2"
                className={classes.topHeader}
            >
                {props.type === "Add" && "Add New Course"}
                {props.type === "Edit" && "Edit Course"}
            </Typography>
            <div className={classes.marginBot24}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '100%',
                            marginRight: '12px',
                            position: 'relative',
                        }}
                    >
                        <InputLabel className={classes.formInputLabel}>
                            Course Name
                        </InputLabel>
                        <TextField
                            name="name"
                            type="text"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={handleChange}
                            value={formInformation?.name}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputLabel className={classes.formInputLabel}>
                            Category
                        </InputLabel>
                        <Select
                            sx={{
                                width: '100%',
                                border: 'none',
                                height: '56px',
                                marginRight: '12px',
                                position: 'relative'
                            }}
                            name="category"
                            onChange={handleChange}
                            className="select-class"
                            value={formInformation?.category}
                        >
                            {universityCat.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item?.cat}
                                >
                                    {item?.cat}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '100%',
                            marginRight: '12px',
                            position: 'relative',
                        }}
                    >
                        <InputLabel className={classes.formInputLabel}>
                            Average Package
                        </InputLabel>
                        <TextField
                            type="text"
                            name="avg_package"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={handleChange}
                            value={formInformation?.avg_package}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputLabel className={classes.formInputLabel}>
                            Average Duration
                        </InputLabel>
                        <TextField
                            type="text"
                            name="avg_duration"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={handleChange}
                            value={formInformation?.avg_duration}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <InputLabel className={classes.formInputLabel}>
                        Sub Description
                    </InputLabel>
                    <TextField
                        type="text"
                        name="sub_description"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        onChange={handleChange}
                        value={formInformation?.sub_description}
                    />
                </div>
            </div>
            <div>
                <InputLabel className={classes.formInputLabel}>
                    Description <span className="optional-text-style">(Optional)</span>
                </InputLabel>
                <div style={{width: '100%', marginBottom: '24px'}}>
                    <JoditEditor 
                        ref={editor}
                        value={formInformation?.description}
                        onChange={(value) => textEditor(value, "description")}
                    />
                </div>
            </div>
            <div>
                <InputLabel className={classes.formInputLabel}>
                    Syllabus <span className="optional-text-style">(Optional)</span>
                </InputLabel>
                <div style={{width: '100%', marginBottom: '24px'}}>
                    <JoditEditor 
                        ref={editor}
                        value={formInformation?.syllabus}
                        onChange={(value) => textEditor(value, "syllabus")}
                    />
                </div>
            </div>
            <div>
                <InputLabel className={classes.formInputLabel}>
                    Jobs <span className="optional-text-style">(Optional)</span>
                </InputLabel>
                <div style={{width: '100%', marginBottom: '24px'}}>
                    <JoditEditor 
                        ref={editor}
                        value={formInformation?.jobs}
                        onChange={(value) => textEditor(value, "jobs")}
                    />
                </div>
            </div>
            <div>
                <InputLabel className={classes.formInputLabel}>
                    Salary <span className="optional-text-style">(Optional)</span>
                </InputLabel>
                <div style={{width: '100%', marginBottom: '24px'}}>
                    <JoditEditor 
                        ref={editor}
                        value={formInformation?.salary}
                        onChange={(value) => textEditor(value, "salary")}
                    />
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Course Logo <span className="optional-text-style">(Optional)</span>
                </InputLabel>
                <div style={{ margin: '8px 24px 0 20px' }}>
                    <UploadBox
                        name="logo-upload"
                        value={formInformation?.logo}
                        handleUpload={(name, value) => handleImageUploads(name, value)}
                    />
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Brochure <span className="optional-text-style">(Optional)</ span>
                </InputLabel>
                <div style={{ margin: '8px 24px 0 20px' }}>
                    <UploadBox
                        name="brochour-upload"
                        value={formInformation?.brochure}
                        handleUpload={(name, value) => handleImageUploads(name, value)}
                    />
                </div>
            </div>
            <Button
                sx={{
                    width: '100%',
                    marginBottom: '12px',
                    backgroundColor: 'var(--primary-color)',
                    '&:hover': { backgroundColor: 'var(--primary-color)' }
                }}
                variant="contained"
                disabled={btnLoader}
                onClick={submit}
            >
                {btnLoader ? <LoaderIcon />  : (props.type === 'Edit' ? 'Update' : 'Submit') }
            </Button>
        </div>
    )
}
export default CourseForm;