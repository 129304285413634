import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  IconButton, 
  Typography } from "@mui/material";
import SearchTextField from "../../components/SearchTextField/SearchTextField";
import MealTable from "../../components/BasicTable/BasicTable";
import Pagination from "../../components/Pagination/Pagination";
import routes from "../../react_routes_enum";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Spinner from "../../components/Spinner/Spinner";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductIcon from '../../assest/images/product_icon.png';
import { toast } from "react-toastify";
import { 
  getUniversities, 
  setUniversityById,
  universityStatusUpdate
} from "../../redux/universities/universitySlice";
import { 
  getProductByInstitute,
  setInstituteIdForProduct 
} from "../../redux/instituteProd/instituteProdSlice";
import { 
  getCoaching, 
  coachingStatusUpdate
} from "../../redux/coaching/coachingSlice";

let requestBody = {
  id: "",
  request_status: "accepted",
  from: "unblock",
};

const Approved = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pagination Required parameters
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [rejectFormBody, setRejectFormBody] = useState(requestBody);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const { 
    isLoading, 
    isError, 
    totalCount, 
    message, 
    searchText,
    universityData,
  } = useSelector((state) => state.university);
  const { type } = useSelector(
    (state) => state.university
  );
  const { 
    isCLoading, 
    isCError, 
    coachingData } = useSelector((state) => state.coaching);
  const colR = ["SNo", "Name of University", "city", "Full Address", "Website Link", "action", "status"];
  const colR2 = ["SNo", "Name of Coaching Center", "city", "Full Address", "Website Link", "product", "action", "status"];

  useEffect(() => {
    let res = null;
    if(type === "University") (res = dispatch(getUniversities({ limit, offset, searchText: "", request_status: "blocked" })));
    if(type === "Coaching") (res = dispatch(getCoaching({ limit, offset, searchText: "", request_status: "blocked" })));
    res?.then((r) => {
      if(r?.payload?.message.toLowerCase() === "unauthorized" || 
        r?.payload?.message === "Session Expired"){
        toast.error("Session expired, please login again");
        localStorage.removeItem("hp_token");
        localStorage.removeItem("hp_user");
        navigate(routes.LOGIN);
      }
    })
  }, [limit, offset, type]);

  const resetStateForPaginationOfDifferentView = () => {
    setLimit(10);
    setOffset(0);
    setPage(0);
  };

  const handleSearchText = (e) => {
    resetStateForPaginationOfDifferentView();
    if (limit === 10 && offset === 0) {
      let res = null;
      type === "University" ? (res = dispatch(getUniversities({ limit, offset, searchText, request_status: "blocked" }))) :
        (res = dispatch(getCoaching({ limit, offset, searchText, request_status: "blocked" })))
      res?.then((r) => {
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
        }
      })
    }
  };

  const getProductList = (id) => {
    let params= {};
    type === "University" ? (params = {limit, offset, university_id: id}) : (params = {limit, offset, coaching_id: id});
    setProductLoader(true)
    dispatch(setInstituteIdForProduct(id))
    const res = dispatch(getProductByInstitute(params));
    res.then((r) => {
      setProductLoader(false);
      if(r.payload.statusCode === 200){
        navigate(routes.PRODUCT_LIST)
      }
      else{
        if(r?.payload?.message.toLowerCase() === "unauthorized" || 
          r?.payload?.message === "Session Expired"){
          toast.error("Session expired, please login again");
          localStorage.removeItem("hp_token");
          localStorage.removeItem("hp_user");
          navigate(routes.LOGIN);
          return;
        }
        toast.error(r?.payload?.message);
      }
    })
  }

  if (isLoading || isCLoading || productLoader) return <Spinner />;
  if (isError || isCError) return <h1>{message}</h1>;
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const unBlockClicked = () => {
    if(type === "University"){
      const res = dispatch(universityStatusUpdate(rejectFormBody));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("University unblocked");
          dispatch(getUniversities({ limit, offset, searchText: "", request_status: "blocked" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }else{
      const res = dispatch(coachingStatusUpdate(rejectFormBody));
      res.then((r) => {
        if(r.payload.statusCode === 200){
          toast.success("Coaching unblocked");
          dispatch(getCoaching({ limit, offset, searchText: "", request_status: "blocked" }));
          setIsDialogOpen(false);
        }else{
          if(r?.payload?.message.toLowerCase() === "unauthorized" || 
            r?.payload?.message === "Session Expired"){
            toast.error("Session expired, please login again");
            localStorage.removeItem("hp_token");
            localStorage.removeItem("hp_user");
            navigate(routes.LOGIN);
            return;
          }
          toast.error(r?.payload?.message);
        }
      })
    }
  }
  return (
    <>
      <Dialog maxWidth="xs" open={isDialogOpen} onClose={handleClose} fullWidth>
        <div>
          <Typography component="p" variant="p" 
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              margin: '28px 0 48px'}}>
            Are you sure you want to<br/> reject the {type} ?
          </Typography>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
            padding: '0px 16px 28px 16px'
          }}>
            <Button onClick={() => {setIsDialogOpen(false)}} variant="outlined" sx={{
              textTransform: 'capitalize'
            }}>
              Cancel
            </Button>
            <Button onClick={() => {unBlockClicked()}} variant="contained" sx={{
              backgroundColor: 'var(--primary-color)',
              width: '84px',
              marginRight: '12px',
              textTransform: 'capitalize'
            }}>
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
      <div style={{marginTop: '3%'}}>
        <SearchTextField
          searchText={searchText}
          handleSearchText={handleSearchText}
          fromWhere="meal"
        />
        <div className="pagination-addPost">
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page={page}
            changeLimit={setLimit}
            changeOffset={setOffset}
            changePage={setPage}
          />
        </div>
        <MealTable
          columns={type === "University" ? colR : colR2}
          where="recipes"
          rows={type === "University" ? (universityData && universityData?.map((item) => {
            const res = {
              ...item,
              // product:(
              //   <div>
              //     <IconButton onClick={() => getProductList(item._id)}
              //       color="inherit"
              //       edge="start"
              //       sx={{
              //         display: 'flex',
              //         flexDirection: 'column'
              //       }}
              //     >
              //       <img src={ProductIcon} alt="Product Icon" width="20" />
              //       <Typography component="p" variant="p" 
              //         sx={{
              //           color: 'var(--primary-color)',
              //           fontSize: '18px',
              //           fontWeight: 'bold',
              //           marginTop: '6px'
              //           }}>
              //         Products
              //       </Typography>
              //     </IconButton>
              //   </div>
              // ),
              action: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '8px'
                    }}
                    onClick={() => {
                      setIsDialogOpen(true);
                      setRejectFormBody({...rejectFormBody, id: item._id})
                    }}
                  >
                    <DoDisturbIcon sx={{color: '#EF0000'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#EF0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Unblock
                    </Typography>
                  </IconButton>
                  <IconButton onClick={() => {
                    dispatch(setUniversityById(item._id));
                  }}
                    color="inherit"
                    edge="start"
                  >
                    <Link to={routes.DETAILS} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </Link>
                  </IconButton>
                </div>
              ),
              status: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                  >
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold'
                        }}>
                      Approved
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          })) :
          (coachingData && coachingData?.map((item) => {
            const res = {
              ...item,
              product:(
                <div>
                  <IconButton onClick={() => getProductList(item._id)}
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <img src={ProductIcon} alt="Product Icon" width="20" />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: 'var(--primary-color)',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Products
                    </Typography>
                  </IconButton>
                </div>
              ),
              action: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '8px'
                    }}
                    onClick={() => {
                      setIsDialogOpen(true);
                      setRejectFormBody({...rejectFormBody, id: item._id})
                    }}
                  >
                    <DoDisturbIcon sx={{color: '#EF0000'}} />
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#EF0000',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginTop: '6px'
                        }}>
                      Unlock
                    </Typography>
                  </IconButton>
                  <IconButton onClick={() => {
                    dispatch(setUniversityById(item._id));
                  }}
                    color="inherit"
                    edge="start"
                  >
                    <Link to={routes.DETAILS} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <VisibilityIcon sx={{color: '#3C1CDE'}} />
                      <Typography component="p" variant="p" 
                        sx={{
                          color: '#3C1CDE',
                          fontSize: '18px',
                          fontWeight: 'bold'
                          }}>
                        View
                      </Typography>
                    </Link>
                  </IconButton>
                </div>
              ),
              status: (
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <IconButton
                    color="inherit"
                    edge="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 8px'
                    }}
                  >
                    <Typography component="p" variant="p" 
                      sx={{
                        color: '#07AB35',
                        fontSize: '18px',
                        fontWeight: 'bold'
                        }}>
                      Approved
                    </Typography>
                  </IconButton>
                </div>
              )
            };
            return res;
          }))
        }
        />
      </div>
    </>
  );
};

export default Approved;
