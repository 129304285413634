import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TextField, InputLabel } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import routes from "../../react_routes_enum";
import DownloadIcon from '@mui/icons-material/Download';
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import UserTable from "../../components/BasicTable/BasicTable";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import { getCallRequests } from "../../redux/students/studentsSlice";

const CallRequests = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);
    const [contactInfo, setContactInfo] = useState({});
    const [userListView, setUserListView] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const { 
        isError,
        message,
        isLoading,
        totalCount,
        callRequestData } = useSelector((state) => state.students)
  
    const columns = [
        "SNo",
        "name",
        "phone",
        "email",
        "Level",
        "Stream"
    ];

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        const res = dispatch(getCallRequests({limit, offset}));
        res?.then((r) => {
            if(r.payload.message === "unauthorized" || 
                r.payload.message === "Unauthorized" ||
                r.payload.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
            }
        })
    }, [limit, offset]);

    if (isLoading) return <Spinner />;

    if (isError) return <h1 style={{ color: "red" }}>{message}</h1>;
  
    return (
        <div style={{
        marginTop: '5%'
        }}>
        <Dialog maxWidth="sm" open={isDialogOpen} onClose={handleClose} fullWidth>
            <form style={{padding: '0 5% 2% 5%'}}>
            <DialogContent>
                <h3 style={{
                fontSize: '28px',
                textAlign: 'center',
                marginBottom: '0px'
                }}>
                    Detail
                </h3>
                <div style={{
                height: '20px',
                width: '100%'
                }}></div>
                {contactInfo?.name &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Name
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.name} 
                            type="text" 
                            name="name" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.email &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Email
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.email} 
                            type="email" 
                            name="email" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.phone &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Phone Number
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.phone} 
                            type="number" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.message &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Message
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.message} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.company_name &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Company Name
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.company_name} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.company_website &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Company Website
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.company_website} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.office_location &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Company Location
                        </InputLabel>
                        <TextField 
                            fullWidth 
                            value={contactInfo?.office_location} 
                            type="text" 
                            name="phone" 
                            variant="outlined" 
                            required />
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.id_proof &&
                <>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginBottom: '4px'
                        }}>
                            Download Firm Document: 
                        </InputLabel>
                        <a href={contactInfo?.firm_document} target="_blank">
                            <DownloadIcon sx={{color: '#000'}} />
                        </a>
                    </div>
                    <div style={{width: '100%', height: '16px'}}></div>
                </>
                }
                {contactInfo?.id_proof &&
                <>
                    <div>
                        <InputLabel sx={{
                            color: '#000',
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }}>
                            Id Proof
                        </InputLabel>
                        <a href={contactInfo?.id_proof} target="_blank">
                            <img 
                                src={contactInfo?.id_proof}
                                height="100"
                                width="100" 
                                />
                        </a>
                    </div>
                </>
                }
            </DialogContent>
            </form>
        </Dialog>
        {userListView && (
            <>
            {/* <SearchTextField
                searchText={searchText}
                handleSearchText={handleSearchText}
                fromWhere="subscribedUsers"
            /> */}
            <Pagination
                totalCount={totalCount}
                limit={limit}
                page={page}
                changeLimit={setLimit}
                changeOffset={setOffset}
                changePage={setPage}
            />
            <UserTable
                columns={columns}
                rows={
                    callRequestData &&
                    callRequestData?.map((item) => {
                    const res = {
                    ...item
                    // Action: (
                    //     <IconButton
                    //     color="inherit"
                    //     edge="start"
                    //     sx={{
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     }}
                    //     onClick={() => {
                    //         setIsDialogOpen(true)
                    //         setContactInfo(item)}}>
                    //     <VisibilityIcon sx={{color: '#3C1CDE'}} />
                    //     <Typography component="p" variant="p" 
                    //     sx={{
                    //         color: '#3C1CDE',
                    //         fontSize: '18px',
                    //         fontWeight: 'bold'
                    //         }}>
                    //     View
                    //     </Typography>
                    // </IconButton>
                    // )
                    };
                    return res;
                })
                }
            />
            </>
        )}
        </div>
    );
};

export default CallRequests;
