import JoditEditor from 'jodit-react';
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { validateUrl } from "../../utils/validateUrl";
import { useDispatch, useSelector } from "react-redux";
import LoaderIcon from "../../components/loader/Loader";
import countryListData from "../../data/countryList.json";
import UploadBox from "../../components/UploadBox/UploadBox";
import { removeEmptyKeys } from '../../utils/removeEmptyKeys';
import { Typography, Select, InputLabel, MenuItem, TextField, Button } from "@mui/material";
import { postNewFormRequest, getUniversityById, updateData } from "../../redux/universities/universitySlice";

const useStyles = makeStyles( theme => ({
    topHeading: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    topHeader: {
        marginBottom: 24,
        fontSize: '34px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    marginBot24: {
        marginBottom: '24px'
    },
}))

let formInfo = {
    university_name: "",
    information: {
        city: "",
        about: "",
        address: "",
        country: "",
        website_link: ""
    },
    uploads: {
        photo_gallery: [""],
        university_logo: "",
        introductory_video: ""
    }
}

const UniversityForm = (props) => {
    const editor = useRef(null);
    const classes = useStyles();
    const naviagte = useNavigate();
    const dispatch = useDispatch();

    const [btnLoader, setBtnLoader] = useState(false);
    const [formInformation, setFormInformation] = useState(formInfo);
    const [gallaryPhotoNumber, setGallaryPhotoNumber] = useState(['']);

    const { isLoading, universityId } = useSelector((state) => state.university);

    useEffect(() => {
        if(props.type === "Edit"){
            const res = dispatch(getUniversityById({universityId}));
            res?.then((r) => {
                if(r?.payload?.statusCode === 200){
                    setFormInformation({
                        university_name: r?.payload?.data?.university_name,
                        information: r?.payload?.data?.information,
                        uploads: r?.payload?.data?.uploads
                    })
                    setGallaryPhotoNumber(r?.payload?.data?.uploads?.photo_gallery)
                    return;
                }
                if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                    r?.payload?.message === "Session Expired"){
                    toast.error("Session expired, please login again");
                    localStorage.removeItem("hp_token");
                    localStorage.removeItem("hp_user");
                    naviagte(routes.LOGIN);
                }
            })
        }
    }, [dispatch, props.type])

    const handleChange = (event) => {
        event.target.name === 'name' && setFormInformation({...formInformation, university_name: event.target.value})
        event.target.name === "city" && setFormInformation({...formInformation, information: {...formInformation.information, city: event.target.value} });
        event.target.name === 'address' && setFormInformation({...formInformation, information: {...formInformation.information, address: event.target.value}});
        event.target.name === "country" && setFormInformation({...formInformation, information: {...formInformation.information, country: event.target.value} });
        event.target.name === 'video' && setFormInformation({...formInformation, uploads: {...formInformation.uploads,  introductory_video: event.target.value}});
        event.target.name === "website_link" && setFormInformation({...formInformation, information: {...formInformation.information, website_link: event.target.value}});
    };

    const handleImageUploads = (name, value) => {
        if(name === "logo-upload"){
            setFormInformation({...formInformation, uploads: {...formInformation.uploads, university_logo: value}})
        }else{
            let array = gallaryPhotoNumber;
            array[parseInt(name.split("-")[2])] = value;
            setGallaryPhotoNumber([...array]);
            setFormInformation({...formInformation, uploads: {...formInformation.uploads, photo_gallery: array}});
        }
    }

    const addMore = (i) => {
        if(gallaryPhotoNumber.length < 5){
            let array = [...gallaryPhotoNumber];
            array.push('')
            setGallaryPhotoNumber([...array]);
        }
        else{
            toast.error("Maximum 5 photo allowed")
        }
    }

    const textEditor = (text) => {
        setFormInformation({
            ...formInformation, information: {
            ...formInformation.information, 
            about: text}})
    }

    const validate = () => {
        const status = {isValid: true, message: ""};
        if(!formInformation?.university_name || formInformation?.university_name === ""){
            status.isValid = false;
            status.message = "University Or College Name Required";
            return status;
        }
        if(!formInformation?.information?.address || formInformation?.information?.address === ""){
            status.isValid = false;
            status.message = "Address Required";
            return status;
        }
        if(!formInformation?.information?.country || formInformation?.information?.country === ""){
            status.isValid = false;
            status.message = "Country Required";
            return status;
        }
        if(!formInformation?.information?.city || formInformation?.information?.city === ""){
            status.isValid = false;
            status.message = "City Required";
            return status;
        }
        if(formInformation.information.website_link && !validateUrl(formInformation.information.website_link)){
            status.isValid = false;
            status.message = "Invalid Website Link Entered";
            return status;
        }
        if(formInformation?.uploads?.introductory_video && !validateUrl(formInformation?.uploads?.introductory_video)){
            status.isValid = false;
            status.message = "Invalid Video Link Entered";
            return status;
        }
        if(!formInformation?.information?.about || formInformation?.information?.about === ""){
            status.isValid = false;
            status.message = "About College Required";
            return status;
        }
        if(formInformation.uploads.photo_gallery.length === 0 || formInformation.uploads.photo_gallery[0] === ""){
            status.isValid = false;
            status.message = "Upload atleast 1 gallery photo";
            return status;
        }
        if(!formInformation.uploads.university_logo || formInformation.uploads.university_logo === ""){
            status.isValid = false;
            status.message = "Logo Required";
            return status;
        }
        return status;
    }

    const submit = () => {
        const status = validate();
        if(status.isValid){
            const body = removeEmptyKeys(formInformation);
            setBtnLoader(true);

            const res = props.type === "Add" ? 
                dispatch(postNewFormRequest(body)) : 
                dispatch(updateData({
                    body,
                    id: universityId,
                    type: "University"
                }))
            res?.then((r) => {
                setBtnLoader(false);
                if(r.payload.statusCode === 200){
                    toast.success(r.payload.message);
                    naviagte(routes.UNIVERSITY_APPROVED);
                    return;
                }
                toast.error(r.payload.message);
            })
            return;
        }
        toast.error(status.message);
    }

    return (
        <div>
            <Typography
                variant="h2"
                component="h2"
                className={classes.topHeader}
            >
                {props.type === "Add" && "Add New University Or College"}
                {props.type === "Edit" && "Edit University Or College"}
            </Typography>
            <div className={classes.marginBot24}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '100%',
                            marginRight: '12px',
                            position: 'relative',
                        }}
                    >
                        <InputLabel className={classes.formInputLabel}>
                            University Name
                        </InputLabel>
                        <TextField
                            name="name"
                            type="text"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={handleChange}
                            value={formInformation?.university_name}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputLabel className={classes.formInputLabel}>
                            Address
                        </InputLabel>
                        <TextField
                            type="text"
                            name="address"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={handleChange}
                            value={formInformation?.information?.address}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '100%',
                            marginRight: '12px',
                            position: 'relative',
                        }}
                    >
                        <InputLabel className={classes.formInputLabel}>
                            Country
                        </InputLabel>
                        <Select
                            sx={{
                                width: '100%',
                                border: 'none',
                                height: '56px',
                                marginRight: '12px',
                                position: 'relative'
                            }}
                            name="country"
                            onChange={handleChange}
                            className="select-class"
                            value={formInformation?.information?.country}
                        >
                            {countryListData.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item?.country}
                                >
                                    {item?.country}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputLabel className={classes.formInputLabel}>
                            City
                        </InputLabel>
                        <TextField 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            name="city"
                            type="text"
                            variant="outlined"
                            onChange={handleChange}
                            value={formInformation?.information?.city}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '100%',
                            marginRight: '12px',
                            position: 'relative',
                        }}
                    >
                        <InputLabel className={classes.formInputLabel}>
                            Website Link <span className="optional-text-style">(Optional)</span>
                        </InputLabel>
                        <TextField
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            type="text"
                            variant="outlined"
                            name="website_link"
                            onChange={handleChange}
                            value={formInformation?.information?.website_link}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputLabel className={classes.formInputLabel}>
                            Introductory / Promotional video Link <span className="optional-text-style">(Optional)</span>
                        </InputLabel>
                        <TextField
                            // onChange={(e) => 
                            //     setUploadInfo({
                            //         ...uploadInfo, 
                            //         uploads: {
                            //             ...uploadInfo.uploads, 
                            //             introductory_video: e.target.value
                            //         }})
                            // }
                            sx={{
                                width: '100%',
                                height: '62px',
                            }}
                            name="video"
                            variant="outlined"
                            onChange={handleChange}
                            value={formInformation?.uploads?.introductory_video}
                        />
                    </div>
                </div>
            </div>
            <div>
                <InputLabel className={classes.formInputLabel}>
                    About University Or College
                </InputLabel>
                <div style={{width: '100%', marginBottom: '24px'}}>
                    <JoditEditor 
                        ref={editor}
                        onChange={textEditor}
                        value={formInformation?.information?.about}
                    />
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Upload Photo Gallery
                </InputLabel>
                <div>
                    {gallaryPhotoNumber?.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'inline-block',
                                margin: '8px 24px 8px 20px'
                            }}
                        >
                            <UploadBox
                                value={item}
                                name={`gallary-upload-${index}`}
                                handleUpload={(name, value) => handleImageUploads(name, value)} 
                            />
                        </div>
                    ))}
                    <div
                        className="add-more-btn"
                        style={{ display: 'inline-block' }}
                    >
                        <Button
                            variant="contained"
                            className={classes.addMore}
                            sx={{
                                backgroundColor: 'var(--primary-color)',
                                '&:hover': { backgroundColor: 'var(--primary-color)' }
                            }}
                            onClick={() => addMore(formInformation?.uploads?.photo_gallery.length)} 
                        >
                            + Add More
                        </Button>
                    </div>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    University Logo
                </InputLabel>
                <div style={{ margin: '8px 24px 0 20px' }}>
                    <UploadBox
                        name="logo-upload"
                        value={formInformation?.uploads?.university_logo}
                        handleUpload={(name, value) => handleImageUploads(name, value)} 
                    />
                </div>
            </div>
            <Button
                sx={{
                    width: '100%',
                    marginBottom: '12px',
                    backgroundColor: 'var(--primary-color)',
                    '&:hover': { backgroundColor: 'var(--primary-color)' }
                }}
                variant="contained"
                disabled={btnLoader}
                onClick={submit}
            >
                {btnLoader ? <LoaderIcon />  : (props.type === 'Edit' ? 'Update' : 'Submit') }
            </Button>
        </div>
    )
}
export default UniversityForm;