import "./App.css";
import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import MbbsAbroad from "./pages/mbbsAbroad/MbbsAbroad";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./pages/auth/Login";
import Layout from "./components/Layout/Layout";
import routes from "./react_routes_enum";
import SubscribedUser from "./pages/Users/SubscribedUser";
import Meal from "./pages/catalogue/meal/Meal";
import Registration from "./pages/registration/Regsitration";
import Approved from "./pages/approved/Approved";
import Rejected from "./pages/rejected/Rejected";
import Review from "./pages/review/Review";
import ListingForm from "./components/listing/ListingForm";
import Students from "./pages/students/Students";
import Details from "./pages/details/Details";
import StudentDetails from "./pages/students/StudentDetail";
import Blocked from "./pages/blocked/Blocked";
import Blogs from "./pages/blogs/Blogs";
import Testimonial from "./pages/testimonial/Testimonial";
import BlogDetail from "./pages/blogs/BlogsDetail";
import BlogEdit from "./pages/blogs/BlogEdit";
import AddTestimonial from "./pages/testimonial/AddTestimonial";
import "react-toastify/dist/ReactToastify.css";
import CreateUniversity from "./pages/create/CreateUniversity";
import Information from "./pages/information/Information";
import Uploads from "./pages/upload/Upload";
import Course from "./pages/course/Course";
import PlacementRecord from "./pages/placementRecord/PlacementRecord";
import InstituteProd from "./pages/instituteProd/InstituteProd";
import ProductForm from "./pages/instituteProd/ProductForm";
import ApplicationList from "./pages/students/ApplicationList";
import ApplicationDetail from "./pages/students/ApplicationDetail";
import HelpSupport from "./pages/help&Support/Help&Support";
import Dashboard from "./pages/dashboard/dashboard";
import TicketDetail from "./pages/help&Support/TicketDetail";
import CustomPages from "./pages/customPages/CustomPages";
import NewCustom from "./pages/customPages/NewCustom";
import PartnerRequests from "./pages/partners/PartnersRequest";
import PartnerRegist from "./pages/partners/PartnerRegist";
import PartnerApproved from "./pages/partners/PartnerApproved";
import PartnerDetails from "./pages/partners/PartnerForm";
import PartnerReview from "./pages/partners/PartnerReview";
import PartnerDocument from "./pages/partners/PartnerDocuments";
import PartnerDocumentReview from "./pages/partners/DocumentReview";
import PartnerRejected from "./pages/partners/PartnerRejected";
import LeadPending from "./pages/leadSystem/LeadPending";
import LeadRejected from "./pages/leadSystem/LeadRejected";
import LeadApproved from "./pages/leadSystem/LeadApproved";
import LeadDetail from "./pages/leadSystem/LeadDetails";
import BlogsForm from "./pages/blogs/BlogForm";
import ContactUs from "./pages/contactUs/Contact";
import CallRequests from "./pages/callRequest/CallRequest";
import CourseOrders from "./pages/courseOrders/CourseOrders";
import AddNewCourse from "./pages/newCourse/NewCourse";
import EditCourse from "./pages/EditCourse/EditCourse";
import AddUniversity from "./pages/createUniversity/CreateUniversity";
import AvailableCourses from "./pages/AvailableCourses/AvailableCourses";
import UniversityEditForm from "./pages/universityEditForm/UniversityEditForm";
import EditUniversityBasic from "./pages/EditUniversityBasic/EditUniversityBasic";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          fontWeight: "600 !important",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#407BFF",
    },
  },
});

function App() {
  const { user } = useSelector((state) => state.auth);
  let location = useLocation();

  return (
    <>
      <ThemeProvider theme={theme}>
        {location.pathname === "/" && (
          <Navigate to={routes.SUB_ADMINS} replace />
        )}
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route
            path="/"
            element={user ? <Layout /> : <Navigate to="/login" replace />}
          >
            <Route element={<SubscribedUser />} path={routes.SUB_ADMINS} />
            <Route element={<CreateUniversity />} path={routes.CREATE_UNIVERSITY} />
            <Route element={<Information />} path={routes.INFORMATION} />
            <Route element={<Uploads />} path={routes.UPLOAD} />
            <Route element={<Course />} path={routes.COURSE} />
            <Route element={<PlacementRecord />} path={routes.PLACEMENT_RECORD} />
            <Route element={<ProductForm />} path={routes.NEW_PRODUCT} />
            <Route element={<ProductForm />} path={routes.Edit_PRODUCT} />
            <Route element={<ProductForm />} path={routes.VIEW_PRODUCT} />

            <Route element={<CreateUniversity />} path={routes.CREATE_COACHING} />
            <Route element={<Information />} path={routes.COACHING_INFORMATION} />
            <Route element={<Uploads />} path={routes.COACHING_UPLOAD} />
            <Route element={<Course />} path={routes.COACHING_COURSE} />
            <Route element={<ApplicationList />} path={routes.APPLICATION_LIST} />
            <Route element={<ApplicationDetail />} path={routes.APPLICATION_DETAIL} />
            <Route element={<Dashboard />} path={routes.DASHBOARD} />
            <Route element={<HelpSupport/>} path={routes.HELP_SUPPORT}></Route>
            <Route element={<TicketDetail/>} path={routes.HELP_SUPPORT_DETAIL}></Route>
            <Route element={<CustomPages/>} path={routes.CUSTOM_PAGES}></Route>
            <Route element={<MbbsAbroad/>} path={routes.MBBS_ABROAD}></Route>
            <Route element={<NewCustom/>} path={routes.NEW_CUSTOM_PAGE}></Route>
            <Route element={<PartnerDetails/>} path={routes.PARTNER_DETAILS}></Route>
            
            <Route element={<Meal />} path={routes.UNIVERSITY_REQUEST} />
            <Route element={<Registration />} path={routes.UNIVERSITY_REGISTRATION} />
            <Route element={<Approved />} path={routes.UNIVERSITY_APPROVED} />
            <Route element={<Rejected />} path={routes.UNIVERSITY_REJECTED} />
            <Route element={<ListingForm />} path={routes.UNIVERSITY_LISTING_FORM} />
            <Route element={<Review />} path={routes.UNIVERSITY_REVIEW_EDIT} />
            <Route element={<Students />} path={routes.STUDENT} />
            <Route element={<StudentDetails />} path={routes.STUDENT_DETAILS} />
            <Route element={<Blocked />} path={routes.UNIVERSITY_BLOCKED} />
            <Route element={<UniversityEditForm />} path={routes.U_EDIT} />
            
            <Route element={<PartnerRequests />} path={routes.PARTNER_REQUEST} />
            <Route element={<PartnerReview />} path={routes.PARTNER_REVIEW} />
            <Route element={<PartnerRegist />} path={routes.PARTNER_REGISTRATION} />
            <Route element={<PartnerDocument />} path={routes.PARTNER_DOCUMENTS} />
            <Route element={<PartnerApproved />} path={routes.PARTNER_APPROVED} />
            <Route element={<PartnerDocumentReview />} path={routes.PARTNER_DOCUMENTS_REVIEW} />
            <Route element={<PartnerRejected />} path={routes.PARTNER_REJECTED} />

            <Route element={<LeadPending />} path={routes.LGS_PENDING} />
            <Route element={<LeadRejected />} path={routes.LGS_REJECTED} />
            <Route element={<LeadApproved />} path={routes.LGS_APPROVED} />
            <Route element={<LeadDetail />} path={routes.LEAD_DETAIL} />

            <Route element={<Meal />} path={routes.COACHING_REQUEST} />
            <Route element={<Registration />} path={routes.COACHING_REGISTRATION} />
            <Route element={<Approved />} path={routes.COACHING_APPROVED} />
            <Route element={<Rejected />} path={routes.COACHING_REJECTED} />
            <Route element={<ListingForm />} path={routes.COACHING_LISTING_FORM} />
            <Route element={<Review />} path={routes.COACHING_REVIEW_EDIT} />
            <Route element={<Details />} path={routes.DETAILS} />
            <Route element={<InstituteProd />} path={routes.PRODUCT_LIST} />
            <Route element={<Blocked />} path={routes.COACHING_BLOCKED} />

            <Route element={<Blogs />} path={routes.BLOGS} />
            <Route element={<Testimonial />} path={routes.TESTIMONIAL} />
            <Route element={<BlogDetail />} path={routes.BLOG_DETAIL} />
            <Route element={<BlogEdit />} path={routes.BLOG_EDIT} />
            <Route element={<BlogsForm />} path={routes.BLOG_FORM} />
            
            <Route element={<CourseOrders />} path={routes.ORDER} />
            <Route element={<CallRequests />} path={routes.CALL_REQUEST} />
            <Route element={<ContactUs />} path={routes.CONTACT_US} />
            <Route element={<AddTestimonial />} path={routes.CREATE_TESTIMONIAL} />
            <Route element={<AddTestimonial />} path={routes.VIEW_TESTIMONIAL} />

            <Route element={<AvailableCourses />} path={routes.AVAILABLE_COURSES} />
            
            <Route element={<AddUniversity />} path={routes.ADD_UNIVERSITY} />
            <Route element={<AddNewCourse />} path={routes.ADD_COURSE} />
            <Route element={<EditCourse />} path={routes.EDIT_COURSE} />
            <Route element={<EditUniversityBasic />} path={routes.EDIT_UNIVERSITY_BASIC} />

            <Route element={<h1>Page not found</h1>} path="*" />
          </Route>
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </>
  );
}

export default App;
