import JoditEditor from 'jodit-react';
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Switch from '@mui/material/Switch';
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderIcon from "../../components/loader/Loader";
import { validateUniv } from "../../utils/vaildateUniv";
import UploadBox from '../../components/UploadBox/UploadBox';
import { removeEmptyKeys } from "../../utils/removeEmptyKeys";
import { getAvailableCourses } from "../../redux/students/studentsSlice";
import { MenuItem, Button, TextField, InputLabel, Select } from "@mui/material";
import { getConfigDetails } from "../../redux/configurationDetails/configDetailsSlice";
import { updateData, getUniversityById } from "../../redux/universities/universitySlice";

const useStyles = makeStyles( theme => ({
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    addMore: {
        background: '#F69220 !important',
        marginTop: '12px !important',
        color: '#FFF !important',
        textTransform: 'capitalize !important',
        borderRadius: '4px !important',
        "&:hover":{
            background: '#F69220',
        }
    },
    submitBtn: {
        width: '100%',
        color: '#FFF !important',
        height: '40px !important',
        minHeight: '40px !important',
        marginTop: '12px !important',
        textTransform: 'capitalize !important',
        borderRadius: '4px !important',
        "&:hover":{
            background: '#F69220',
        }
    },
    marginBot24: {
        marginBottom: '24px'
    }
}))

const courseType = {
    description: "",
    feeStructure: [
        {
            fees: "",
            course: "",
            duration: ""
        }
    ],
    examsAccepted: [
        {
            exams: "",
            course: "",
            coachings: ""
        }
    ]
}
const placementType = {
    description: "",
    record: [
        {
            year: "",
            course: "",
            highestPackage: ""
        }
    ]
}
const rankingType = {
    description: "",
    rankDetail: [
        {
            course: "",
            nirfRank: "",
            nirfScore: ""
        }
    ]
}
const scholarshipType = [
    {
        name: "",
        benefits: [],
        eligibility: []
    }
]
const campusType = {
    description: "",
    hostelFees: "",
    facilities: "",
    warden: [
        {
            name: "",
            warden: "",
            phone: ""
        }
    ]
}
const admissionType = {
    description: "",
    course: [
        {
            name: "",
            keyPoints: []
        }
    ]
}

const data = {
    rank: 0,
    type: "",
    brochure: "",
    is_top: false,
    study_mode: "",
    category: "",
    approved_by: "",
    sub_description: "",
    courses: courseType,
    placement: placementType,
    ranking: rankingType,
    scholarships: scholarshipType,
    campus: campusType,
    admission: admissionType
}
const UniversityEditForm = () => {
    const classes = useStyles();
    const editor = useRef(null);
    const naviagte = useNavigate();
    const dispatch = useDispatch();
    
    const [benefits, setBenefits] = useState("");
    const [formData, setFormData] = useState(data);
    const [eligibility, setEligibility] = useState("");
    const [universityCat, setUniversityCat] = useState([]);
    const [admissionKeyPoints, setAdmissionKeyPoints] = useState("");

    const { availableCourseData } = useSelector((state) => state.students);
    const { isLoading, universityId } = useSelector((state) => state.university);

    useEffect(() => {
        const res = dispatch(getAvailableCourses({limit: 50, offset: 0}));
        res?.then((r) => {
            if(r.payload.message === "unauthorized" || 
                r.payload.message === "Unauthorized" ||
                r.payload.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                naviagte(routes.LOGIN);
            }
        })
    }, [dispatch]);

    useEffect(() => {
        const res = dispatch(getConfigDetails());
        res.then((r) => {
            if(r?.payload?.data){
                r?.payload?.data?.map((item) => {
                    if(item.configuration_type === "university_category"){
                        setUniversityCat(item?.data)
                    }
                })
            }
        })
    }, [])

    useEffect(() => {
        if(!universityId) naviagte(-1)
        let res = dispatch(getUniversityById({universityId}));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setFormData({
                    rank: r?.payload?.data?.rank ?? 0,
                    type: r?.payload?.data?.type ?? "",
                    brochure: r?.payload?.data?.brochure ?? "",
                    is_top: r?.payload?.data?.is_top ?? false,
                    study_mode: r?.payload?.data?.study_mode ?? "",
                    category: r?.payload?.data?.category ?? "",
                    approved_by: r?.payload?.data?.approved_by ?? "",
                    sub_description: r?.payload?.data?.sub_description ?? "",
                    courses: r?.payload?.data?.courses ?? courseType,
                    placement: r?.payload?.data?.placement ?? placementType,
                    ranking: r?.payload?.data?.ranking ?? rankingType,
                    scholarships: r?.payload?.data?.scholarships ?? scholarshipType,
                    campus: r?.payload?.data?.campus ?? campusType,
                    admission: r?.payload?.data?.admission ?? admissionType,

                })
                return;
            }
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                naviagte(routes.LOGIN);
            }
        })
    }, [universityId]);

    const handleChange = (e) => {
        e.target.name === "isTop" && setFormData({...formData, is_top: e.target.checked});
        e.target.name === "rank" && setFormData({...formData, rank: e.target.value});
        e.target.name === "type" && setFormData({...formData, type: e.target.value});
        e.target.name === "study_mode" && setFormData({...formData, study_mode: e.target.value});
        e.target.name === "approved_by" && setFormData({...formData, approved_by: e.target.value});
        e.target.name === "sub_description" && setFormData({...formData, sub_description: e.target.value});
        e.target.name === "category" && setFormData({...formData, category: e.target.value});
    }

    const handlePlacementChange = (e) => {
        if(e.target.name === "placement_description"){
            setFormData({
                ...formData, 
                placement: {
                    ...formData.placement, 
                    description: e.target.value
                }
            });
            return;
        }
        let array = [...formData?.placement?.record];
        array = array.map((element, ind) => {
            return {
                ...element,
                ...(e.target.name === `year_${ind}` && {year: e.target.value}),
                ...(e.target.name === `course_${ind}` && {course: e.target.value}),
                ...(e.target.name === `package_${ind}` && {highestPackage: e.target.value})
            }
        })
        setFormData({
            ...formData,
            placement: {
                ...formData.placement,
                record: [...array]
            }
        });
    }

    const handleRankChange = (e) => {
        if(e.target.name === "rank_description"){
            setFormData({
                ...formData, 
                ranking: {
                    ...formData.ranking, 
                    description: e.target.value
                }
            });
            return;
        }
        let array = [...formData?.ranking?.rankDetail];
        array = array.map((element, ind) => {
            return {
                ...element,
                ...(e.target.name === `course_${ind}` && {course: e.target.value}),
                ...(e.target.name === `nirf_rank_${ind}` && {nirfRank: e.target.value}),
                ...(e.target.name === `nirf_score_${ind}` && {nirfScore: e.target.value})
            }
        })
        setFormData({
            ...formData,
            ranking: {
                ...formData.ranking,
                rankDetail: [...array]
            }
        });
    }

    const handleCourseChange = (e) => {
        if(e.target.name === "course_description"){
            setFormData({
                ...formData,
                courses: {
                    ...formData.courses,
                    description: e.target.value
                }
            });
            return;
        }
        let array = [...formData?.courses?.feeStructure];
        array = array.map((element, ind) => {
            return {
                ...element,
                ...(e.target.name === `course_${ind}` && {course: e.target.value}),
                ...(e.target.name === `fees_${ind}` && {fees: e.target.value}),
                ...(e.target.name === `duration_${ind}` && {duration: e.target.value})
            }
        })
        setFormData({
            ...formData,
            courses: {
                ...formData.courses,
                feeStructure: [...array]
            }
        });
    }

    const handleCourseExamChange = (e) => {
        let array = [...formData?.courses?.examsAccepted];
        array = array.map((element, ind) => {
            return {
                ...element,
                ...(e.target.name === `exams_${ind}` && {exams: e.target.value}),
                ...(e.target.name === `course_${ind}` && {course: e.target.value}),
                ...(e.target.name === `coachings_${ind}` && {coachings: e.target.value})
            }
        })
        setFormData({
            ...formData,
            courses: {
                ...formData.courses,
                examsAccepted: [...array]
            }
        });
    }

    const handleScholarshipChange = (e) => {
        let array = [...formData?.scholarships];
        
        array = array.map((element, ind) => {
            let arrayData = [];
            if((ind === Number(e.target.name.split("_")[1])) && 
                (e.target.name.split("_")[0] === "benefit")){
                arrayData = element?.benefits;
                arrayData.push(e.target.value);
            }
            if((ind === Number(e.target.name.split("_")[1])) && 
                (e.target.name.split("_")[0] === "eligibility")){
                arrayData = element?.eligibility;
                arrayData.push(e.target.value);
            }
            return {
                ...element,
                ...(e.target.name === `name_${ind}` && {name: e.target.value}),
                ...(e.target.name === `benefit_${ind}` && {benefits: [...arrayData]}),
                ...(e.target.name === `eligibility_${ind}` && {eligibility: [...arrayData]})
            }
        })

        setFormData({
            ...formData,
            scholarships: [...array]
        });
    }

    const handleAdmissionChange = (e) => {
        if(e.target.name === "admission_description"){
            setFormData({
                ...formData, 
                admission: {
                    ...formData.admission, 
                    description: e.target.value
                }
            });
            return;
        }
        let array = [...formData?.admission?.course];
        array = array.map((element, ind) => {
            let arrayData = [];
            if((ind === Number(e.target.name.split("_")[2])) &&
                (e.target.name.split("_")[0] === "admission")){
                arrayData = element?.keyPoints;
                arrayData.push(e.target.value);
            }
            return {
                ...element,
                ...(e.target.name === `course_${ind}` && {name: e.target.value}),
                ...(e.target.name === `admission_keypoints_${ind}` && {keyPoints: [...arrayData]})
            }
        })
        setFormData({
            ...formData,
            admission: {
                ...formData.admission,
                course: [...array]
            }
        });
    }

    const handleCampusChange = (e) => {
        if(e.target.name === "campus_description"){
            setFormData({
                ...formData,
                campus: {
                    ...formData.campus,
                    description: e.target.value
                }
            });
            return;
        }
        if(e.target.name === "campus_hostel_fees"){
            setFormData({
                ...formData,
                campus: {
                    ...formData.campus,
                    hostelFees: e.target.value
                }
            });
            return;
        }
        if(e.target.name === "campus_hostel_facilities"){
            setFormData({
                ...formData,
                campus: {
                    ...formData.campus,
                    facilities: e.target.value
                }
            });
            return;
        }
        let array = [...formData?.campus?.warden];
        array = array.map((element, ind) => {
            return {
                ...element,
                ...(e.target.name === `name_${ind}` && {name: e.target.value}),
                ...(e.target.name === `warden_${ind}` && {warden: e.target.value}),
                ...(e.target.name === `phone_${ind}` && {phone: e.target.value})
            }
        })
        setFormData({
            ...formData,
            campus: {
                ...formData.campus,
                warden: [...array]
            }
        });
    }

    const submit = () => {
        const status = validateUniv(formData);
        if(status.isValid){
            const body = removeEmptyKeys(formData)
            const res = dispatch(updateData({
                body,
                id: universityId,
                type: "University"
            }));
            res?.then((r) => {
                r?.payload?.statusCode === 200 ? 
                    toast.success("Data Updated Successfully") :
                    toast.error(r?.payload?.message)
            })
        }
        else{
            toast.error(status.message);
        }
    }

    const handleUploadChange = (name, value) => {
        setFormData({...formData, brochure: value});
    }

    const addMore = (text, arrayData) => {
        let array = [...arrayData];
        if(text === "placement"){
            array.push({
                year: "",
                course: "",
                highestPackage: ""
            });
            setFormData({
                ...formData,
                placement: {
                    ...formData.placement,
                    record: [...array]
                }
            })
        }
        if(text === "rank"){
            array.push({
                course: "",
                nirfRank: "",
                nirfScore: ""
            });
            setFormData({
                ...formData,
                ranking: {
                    ...formData.ranking,
                    rankDetail: [...array]
                }
            })
        }
        if(text === "courseFee"){
            array.push({
                fees: "",
                course: "",
                duration: ""
            });
            setFormData({
                ...formData,
                courses: {
                    ...formData.courses,
                    feeStructure: [...array]
                }
            })
        }
        if(text === "examsAccepted"){
            array.push({
                exams: "",
                course: "",
                coachings: [
                    {
                        id: "",
                        name: ""
                    }
                ]
            });
            setFormData({
                ...formData,
                courses: {
                    ...formData.courses,
                    examsAccepted: [...array]
                }
            })
        }
        if(text === "scholarship"){
            array.push({
                name: "",
                benefits: [],
                eligibility: []
            });
            setFormData({
                ...formData,
                scholarships: [...array]
            })
        }
        if(text === "warden"){
            array.push({
                name: "",
                warden: "",
                phone: ""
            });
            setFormData({
                ...formData,
                campus: {
                    ...formData.campus,
                    warden: [...array]
                }
            })
        }
        if(text === "admission"){
            array.push({
                name: "",
                keyPoints: []
            });
            setFormData({
                ...formData,
                admission: {
                    ...formData.admission,
                    course: [...array]
                }
            })
        }
    }

    const handleRemove = (text, arrayData, index) => {
        let array = [...arrayData];
        if(text === "placement"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                placement: {
                    ...formData.placement,
                    record: [...array]
                }
            })
        }
        if(text === "rank"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                ranking: {
                    ...formData.ranking,
                    rankDetail: [...array]
                }
            })
        }
        if(text === "courseFee"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                courses: {
                    ...formData.courses,
                    feeStructure: [...array]
                }
            })
        }
        if(text === "examsAccepted"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                courses: {
                    ...formData.courses,
                    examsAccepted: [...array]
                }
            })
        }
        if(text === "scholarship"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                scholarships: [...array]
            })
        }
        if(text === "warden"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                campus: {
                    ...formData.campus,
                    warden: [...array]
                }
            })
        }
        if(text === "admission"){
            array.splice(index, 1);
            setFormData({
                ...formData,
                admission: {
                    ...formData.admission,
                    course: [...array]
                }
            })
        }
    }

    const removeNestedItem = (text, index, ind, arrayItem) => {
        let array = [...arrayItem];
        array.splice(ind, 1);

        if(text === "eligibility"){
            setFormData({
                ...formData,
                scholarships: formData?.scholarships?.map((item, indx) => {
                    if(indx === index){
                        item.eligibility = [...array];
                        return item;
                    }
                    return item;
                })
            });
        }
        if(text === "benefits"){
            setFormData({
                ...formData,
                scholarships: formData?.scholarships?.map((item, indx) => {
                    if(indx === index){
                        item.benefits = [...array];
                        return item;
                    }
                    return item;
                })
            });
        }
        if(text === "keyPoints"){
            setFormData({
                ...formData,
                admission: {
                    ...formData?.admission,
                    course: formData?.admission?.course.map((item, indx) => {
                        if(indx === index){
                            item.keyPoints = [...array];
                            return item;
                        }
                        return item;
                    })
                }
            });
        }

    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleScholarshipChange(event);
            setBenefits("");
            setEligibility("");
        }
    }

    const handleAdmissionKeyDown = (event) => {
        if (event.key === "Enter") {
            handleAdmissionChange(event);
            setAdmissionKeyPoints("");
        }
    }

    const handleEditorChange = (type, value) => {
        if(type === "course"){
            setFormData({
                ...formData,
                courses: {
                    ...formData.courses,
                    description: value
                }
            });
            return
        }
        if(type === "rank"){
            setFormData({
                ...formData,
                ranking: {
                    ...formData.ranking,
                    description: value
                }
            });
            return
        }
        if(type === "placement"){
            setFormData({
                ...formData, 
                placement: {
                    ...formData.placement, 
                    description: value
                }
            });
            return
        }
        if(type === "campus"){
            setFormData({
                ...formData,
                campus: {
                    ...formData.campus,
                    description: value
                }
            });
            return
        }
        if(type === "admission"){
            setFormData({
                ...formData, 
                admission: {
                    ...formData.admission, 
                    description: value
                }
            });
            return
        }

    }

    return (
        <div style={{marginTop: '3%'}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
                className={classes.marginBot24}
            >
                <InputLabel className={classes.formInputLabel}>
                    Is It Top University ?
                </InputLabel>
                <Switch
                    name="isTop"
                    defaultChecked
                    onChange={handleChange}
                    checked={formData.is_top}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={classes.marginBot24} style={{width: '32%'}}>
                    <InputLabel className={classes.formInputLabel}>
                        Rank
                    </InputLabel>
                    <TextField
                        name="rank"
                        type="number"
                        placeholder=""
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        onChange={handleChange}
                        value={formData.rank}
                    />
                </div>
                <div className={classes.marginBot24} style={{width: '32%'}}>
                    <InputLabel className={classes.formInputLabel}>
                        Type
                    </InputLabel>
                    <Select
                        label=""
                        name="type"
                        sx={{ width: '100%' }}
                        value={formData?.type}
                        onChange={handleChange}
                        labelId="demo-simple-select-label_1"
                    >
                        <MenuItem value="Private">Private</MenuItem>
                        <MenuItem value="Government">Government</MenuItem>
                        <MenuItem value="Semi-Government">Semi-Government</MenuItem>
                    </Select>
                </div>
                <div className={classes.marginBot24} style={{width: '32%'}}>
                    <InputLabel className={classes.formInputLabel}>
                        Study Mode
                    </InputLabel>
                    <Select
                        label=""
                        name="study_mode"
                        sx={{ width: '100%' }}
                        onChange={handleChange}
                        value={formData?.study_mode}
                        labelId="demo-simple-select-label_2"
                    >
                        <MenuItem value="Online">Online</MenuItem>
                        <MenuItem value="Offline">Offline</MenuItem>
                        <MenuItem value="Both">Both</MenuItem>
                    </Select>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={classes.marginBot24} style={{width: '32%'}}>
                    <InputLabel className={classes.formInputLabel}>
                        Approved By
                    </InputLabel>
                    <TextField
                        type="text"
                        name="approved_by"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        onChange={handleChange}
                        value={formData?.approved_by}
                    />
                </div>
                <div className={classes.marginBot24} style={{width: '32%'}}>
                    <InputLabel className={classes.formInputLabel}>
                        Sub Description
                    </InputLabel>
                    <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="sub_description"
                        onChange={handleChange}
                        value={formData?.sub_description}
                    />
                </div>
                <div className={classes.marginBot24} style={{width: '32%'}}>
                    <InputLabel className={classes.formInputLabel}>
                        Category
                    </InputLabel>
                    <Select
                        label=""
                        name="category"
                        sx={{ width: '100%' }}
                        onChange={handleChange}
                        value={formData?.category}
                        labelId="demo-simple-select-label_2"
                    >
                        {universityCat?.map((item, index) => (
                            <MenuItem value={item?.cat} key={index}>
                                {item?.cat}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Brochure
                </InputLabel>
                <UploadBox
                    width="220px"
                    height="194px"
                    name="brochour-upload"
                    value={formData?.brochure}
                    handleUpload={(name, value) => handleUploadChange(name, value)}
                />
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Placement Detail
                </InputLabel>
                <div
                    style={{
                        padding: '12px',
                        borderRadius: '6px',
                        border: '1px solid'
                    }}
                >
                    <InputLabel className={classes.formInputLabel}>
                        Description
                    </InputLabel>
                    {/* <TextField
                        type="text"
                        placeholder=""
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="placement_description"
                        onChange={handlePlacementChange}
                        value={formData?.placement?.description}
                    /> */}
                    <div style={{marginBottom: '8px'}}>
                        <JoditEditor 
                            ref={editor}
                            value={formData?.placement?.description}
                            onChange={(e) => handleEditorChange("placement", e)}
                        />
                    </div>
                    {formData?.placement?.record?.map((item, index) => (
                        <div key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Record {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("placement", formData?.placement?.record, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Year
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item.year}
                                        name={"year_"+index}
                                        onChange={handlePlacementChange}
                                    />
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course
                                    </InputLabel>
                                    {/* <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.course}
                                        name={"course_"+index}
                                        onChange={handlePlacementChange}
                                    /> */}
                                    <Select
                                        label=""
                                        value={item?.course}
                                        name={"course_"+index}
                                        sx={{ width: '100%' }}
                                        onChange={handlePlacementChange}
                                        labelId="demo-simple-select-label_2"
                                    >
                                        {availableCourseData?.map((item, index) => (
                                            <MenuItem value={item?.name} key={index}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Highest Package
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        name={"package_"+index}
                                        value={item?.highestPackage}
                                        onChange={handlePlacementChange}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("placement", formData?.placement?.record)}
                    >
                        Add More Record
                    </Button>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Rank Details
                </InputLabel>
                <div
                    style={{
                        padding: '12px',
                        borderRadius: '6px',
                        border: '1px solid'
                    }}
                >
                    <InputLabel className={classes.formInputLabel}>
                        Description
                    </InputLabel>
                    {/* <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="rank_description"
                        onChange={handleRankChange}
                        value={formData?.ranking?.description}
                    /> */}
                    <div style={{marginBottom: '8px'}}>
                        <JoditEditor 
                            ref={editor}
                            value={formData?.ranking?.description}
                            onChange={(e) => handleEditorChange("rank", e)}
                        />
                    </div>
                    {formData?.ranking?.rankDetail?.map((item, index) => (
                        <div key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Rank Detail {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("rank", formData?.ranking?.rankDetail, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course
                                    </InputLabel>
                                    {/* <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.course}
                                        name={"course_"+index}
                                        onChange={handleRankChange}
                                    /> */}
                                    <Select
                                        label=""
                                        value={item?.course}
                                        name={"course_"+index}
                                        sx={{ width: '100%' }}
                                        onChange={handleRankChange}
                                        labelId="demo-simple-select-label_2"
                                    >
                                        {availableCourseData?.map((item, index) => (
                                            <MenuItem value={item?.name} key={index}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        NIRF Rank
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.nirfRank}
                                        name={"nirf_rank_"+index}
                                        onChange={handleRankChange}
                                    />
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        NIRF Score
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.nirfScore}
                                        name={"nirf_score_"+index}
                                        onChange={handleRankChange}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("rank", formData?.ranking?.rankDetail)}
                    >
                        Add More Rank
                    </Button>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Course
                </InputLabel>
                <div
                    style={{
                        padding: '12px',
                        borderRadius: '6px',
                        border: '1px solid'
                    }}
                >
                    <InputLabel className={classes.formInputLabel}>
                        Description
                    </InputLabel>
                    {/* <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="course_description"
                        onChange={handleCourseChange}
                        value={formData?.courses?.description}
                    /> */}
                    <div style={{marginBottom: '8px'}}>
                        <JoditEditor 
                            ref={editor}
                            value={formData?.courses?.description}
                            onChange={(e) => handleEditorChange("course", e)}
                        />
                    </div>
                    {formData?.courses?.feeStructure?.map((item, index) => (
                        <div key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Course {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("courseFee", formData?.courses?.feeStructure, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course
                                    </InputLabel>
                                    {/* <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.course}
                                        name={"course_"+index}
                                        onChange={handleCourseChange}
                                    /> */}
                                    <Select
                                        label=""
                                        value={item?.course}
                                        name={"course_"+index}
                                        sx={{ width: '100%' }}
                                        onChange={handleCourseChange}
                                        labelId="demo-simple-select-label_2"
                                    >
                                        {availableCourseData?.map((item, index) => (
                                            <MenuItem value={item?.name} key={index}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Fees
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.fees}
                                        name={"fees_"+index}
                                        onChange={handleCourseChange}
                                        
                                    />
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Duration
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.duration}
                                        name={"duration_"+index}
                                        onChange={handleCourseChange}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("courseFee", formData?.courses?.feeStructure)}
                    >
                        Add More Course
                    </Button>
                    {formData?.courses?.examsAccepted?.map((item, index) => (
                        <div key={index}>
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: '8px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <InputLabel className={classes.formInputLabel}>
                                    Exam {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("examsAccepted", formData?.courses?.examsAccepted, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Exams&nbsp;
                                        <span className="optional-text">
                                            ( Use [ , ] to enter more exams )
                                        </span>
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.exams}
                                        name={"exams_"+index}
                                        onChange={handleCourseExamChange}
                                    />
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course
                                    </InputLabel>
                                    {/* <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.course}
                                        name={"course_"+index}
                                        onChange={handleCourseExamChange}
                                    /> */}
                                    <Select
                                        label=""
                                        value={item?.course}
                                        name={"course_"+index}
                                        sx={{ width: '100%' }}
                                        onChange={handleCourseExamChange}
                                        labelId="demo-simple-select-label_2"
                                    >
                                        {availableCourseData?.map((item, index) => (
                                            <MenuItem value={item?.name} key={index}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Coachings&nbsp;
                                        <span className="optional-text">
                                            ( Use [ , ] to enter more coaching )
                                        </span>
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.coachings}
                                        name={"coachings_"+index}
                                        onChange={handleCourseExamChange}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("examsAccepted", formData?.courses?.examsAccepted)}
                    >
                        Add More Exam
                    </Button>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Scholarships
                </InputLabel>
                <div
                    style={{
                        padding: '12px',
                        border: '1px solid',
                        borderRadius: '6px'
                    }}
                >
                    {formData?.scholarships?.map((item, index) => (
                        <div key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Scholarship {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("scholarship", formData?.scholarships, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div>
                                <InputLabel className={classes.formInputLabel}>
                                    Name
                                </InputLabel>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }}
                                    value={item?.name}
                                    name={"name_"+index}
                                    onChange={handleScholarshipChange}
                                />
                            </div>
                            <div>
                                <InputLabel className={classes.formInputLabel}>
                                    Eligibility&nbsp;
                                    <span className="optional-text">
                                        ( Press enter to add )
                                    </span>
                                </InputLabel>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }}
                                    value={eligibility}
                                    name={"eligibility_"+index}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => setEligibility(e.target.value)}
                                />
                                {item?.eligibility?.map((itm, ind) => (
                                    <div
                                        key={ind}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            {ind+1}.&nbsp;
                                            <p style={{margin: 0}}>
                                                {itm}
                                            </p>
                                        </div>
                                        <Button
                                            type="button"
                                            onClick={() => removeNestedItem("eligibility", index, ind, item?.eligibility)}
                                        >
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <InputLabel className={classes.formInputLabel}>
                                    Benefits&nbsp;
                                    <span className="optional-text">
                                        ( Press enter to add )
                                    </span>
                                </InputLabel>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }}
                                    value={benefits}
                                    name={"benefit_"+index}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => setBenefits(e.target.value)}
                                />
                                {item?.benefits?.map((itm, ind) => (
                                    <div
                                        key={ind}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            {ind+1}.&nbsp;
                                            <p style={{margin: 0}}>
                                                {itm}
                                            </p>
                                        </div>
                                        <Button
                                            type="button"
                                            onClick={() => removeNestedItem("benefits", index, ind, item?.benefits)}
                                        >
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("scholarship", formData?.scholarships)}
                    >
                        Add More Scholarship
                    </Button>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Campus Detail
                </InputLabel>
                <div
                    style={{
                        padding: '12px',
                        borderRadius: '6px',
                        border: '1px solid'
                    }}
                >
                    <InputLabel className={classes.formInputLabel}>
                        Description
                    </InputLabel>
                    {/* <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="campus_description"
                        onChange={handleCampusChange}
                        value={formData?.campus?.description}
                    /> */}
                    <div style={{marginBottom: '8px'}}>
                        <JoditEditor 
                            ref={editor}
                            value={formData?.campus?.description}
                            onChange={(e) => handleEditorChange("campus", e)}
                        />
                    </div>
                    <InputLabel className={classes.formInputLabel}>
                        Hostel Fees
                    </InputLabel>
                    <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="campus_hostel_fees"
                        onChange={handleCampusChange}
                        value={formData?.campus?.hostelFees}
                    />
                    <InputLabel className={classes.formInputLabel}>
                        Facilities&nbsp;
                        <span className="optional-text">
                            ( Use [ , ] to enter more values )
                        </span>
                    </InputLabel>
                    <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        onChange={handleCampusChange}
                        name="campus_hostel_facilities"
                        value={formData?.campus?.facilities}
                    />
                    {formData?.campus?.warden?.map((item, index) => (
                        <div key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Warden {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("warden", formData?.campus?.warden, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Name
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.name}
                                        name={"name_"+index}
                                        onChange={handleCampusChange}
                                    />
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Warden
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.warden}
                                        name={"warden_"+index}
                                        onChange={handleCampusChange}
                                    />
                                </div>
                                <div style={{width: '32%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Phone
                                    </InputLabel>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.phone}
                                        name={"phone_"+index}
                                        onChange={handleCampusChange}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("warden", formData?.campus?.warden)}
                    >
                        Add More Warden
                    </Button>
                </div>
            </div>
            <div className={classes.marginBot24}>
                <InputLabel className={classes.formInputLabel}>
                    Admission Requirements
                </InputLabel>
                <div
                    style={{
                        padding: '12px',
                        borderRadius: '6px',
                        border: '1px solid'
                    }}
                >
                    <InputLabel className={classes.formInputLabel}>
                        Description
                    </InputLabel>
                    {/* <TextField
                        type="text"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '62px'
                        }}
                        name="admission_description"
                        onChange={handleAdmissionChange}
                        value={formData?.admission?.description}
                    /> */}
                    <div style={{marginBottom: '8px'}}>
                        <JoditEditor 
                            ref={editor}
                            value={formData?.admission?.description}
                            onChange={(e) => handleEditorChange("admission", e)}
                        />
                    </div>
                    {formData?.admission?.course?.map((item, index) => (
                        <div key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <InputLabel className={classes.formInputLabel}>
                                    Course {index + 1}
                                </InputLabel>
                                <Button
                                    type="button"
                                    onClick={() => handleRemove("admission", formData?.admission?.course, index)}
                                >
                                    <CloseIcon />
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div style={{width: '49%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course Name
                                    </InputLabel>
                                    {/* <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={item?.name}
                                        name={"course_"+index}
                                        onChange={handleAdmissionChange}
                                    /> */}
                                    <Select
                                        label=""
                                        value={item?.course}
                                        name={"course_"+index}
                                        sx={{ width: '100%' }}
                                        onChange={handleAdmissionChange}
                                        labelId="demo-simple-select-label_2"
                                    >
                                        {availableCourseData?.map((item, index) => (
                                            <MenuItem value={item?.name} key={index}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: '49%'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Requirements&nbsp;
                                        <span className="optional-text">
                                            ( Press enter to add )
                                        </span>
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        value={admissionKeyPoints}
                                        name={"admission_keypoints_"+index}
                                        onKeyDown={(e) => handleAdmissionKeyDown(e)}
                                        onChange={(e) => setAdmissionKeyPoints(e.target.value)}
                                    />
                                </div>
                            </div>
                            {item?.keyPoints?.map((itm, ind) => (
                                <div
                                    key={ind}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        {ind+1}.&nbsp;
                                        <p style={{margin: 0}}>
                                            {itm}
                                        </p>
                                    </div>
                                    <Button
                                        type="button"
                                        onClick={() => removeNestedItem("keyPoints", index, ind, item?.keyPoints)}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </div>
                            ))}
                        </div>
                    ))}
                    <Button
                        type="button"
                        className={classes.addMore}
                        onClick={() => addMore("admission", formData?.admission?.course)}
                    >
                        Add More Course
                    </Button>
                </div>
            </div>
            <Button
                type="button"
                onClick={submit}
                disabled={isLoading}
                className={classes.submitBtn}
                style={{backgroundColor: isLoading ? 'rgb(197 196 196)' : '#672F8F'}}
            >
                {isLoading ? <LoaderIcon/> : <span>Submit</span>}
            </Button>
        </div>
    )
}
export default UniversityEditForm;