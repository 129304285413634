import React from "react";
import { Link, useLocation } from "react-router-dom";
import { 
  Button, 
  Typography,
  Input
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import form from '../../assest/svgs/form.svg';
import upload from '../../assest/svgs/upload.svg';
import { makeStyles } from "@mui/styles";
import routes from "../../react_routes_enum";
import Loader from "../../assest/images/preloader.gif";
import getImageUrls from "../../api/uploadImageOnS3";
import { addListing } from "../../redux/universities/universitySlice";
import { setUniversityByIdData } from "../../redux/universities/universitySlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { 
    setProductDetailsById 
} from "../../redux/instituteProd/instituteProdSlice";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    topHeader: {
        fontSize: '34px !important',
        fontWeight: 'bold !important'
    },
    bottomBox: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: '6%'
    },
    boxHeading: {
        fontSize: '30px !important',
        fontWeight: 'bold !important',
        marginBottom: '16% !important'
    },
    leftBox: {
        height: '334px',
        width: '334px',
        borderRadius: '8px',
        border: '2px dashed rgb(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rightBox: {
        height: '334px',
        width: '334px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    rightBigBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2)',
        fontSize: '15px',
        color: 'rgb(0, 0, 0, 0.4)',
        textTransform: 'capitalize',
        width: '122px',
        height: '34px'
    },
    verticalLine: {
        height: '200px',
        borderLeft: '2px dashed rgb(0, 0, 0, 0.5)'
    },
    startButton: {
        backgroundColor: '#672F8F', 
        color: '#fff', 
        width: '140px',
        marginTop: '16px',
        "&:hover": {
            backgroundColor: "#672F8F",
        },
    }
}))

const Listing = (props, {back, progress}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState();
  const [image, setImage] = React.useState('');
  const [error, setError] = React.useState(false);
  const location = useLocation();
  const { type } = useSelector(
    (state) => state.university
  );

  const handleChange = (e) => {
    if(e.target.name === "image-upload"){
        handleUploadFilesOnS3(e.target.files[0]);
        // imgRes.then((r) => console.log("res===",r))
        // console.log("response====",imgRes);
        setImage(e.target.value);
    }
  }
  const handleUploadFilesOnS3 = async(image) => {
    const response = await getImageUrls({
        image: image,
        setLoading,
        setError,
    });
    if(response?.data?.urls[0]){
        let res = dispatch(addListing({listing_for: type, document_url: response?.data?.urls[0]}));
        console.log(res);
        res.then((r) =>{
            if(r.payload.statusCode === 200){
                toast.success("Listing Uploaded");
            }else{
                toast.error("Error While Uploading Listing");
            }
        })
    }else{
        toast.error("Error While Uploading Listing");
    }
    // setFormInformation({...formInformation, address_proof: response?.data?.urls[0]})
    }
    return (
        <>
        <div style={{
            marginTop: '3%'
        }}>
            <div className={classes.root}>
                <Button onClick={() => props.back()}>
                    <ArrowBackIosIcon />
                </Button>
                <Typography component="h2" variant="h2" 
                    className={classes.topHeader}>
                        {props.form === "product" ? "Add Product" : "Add Listing"}
                    </Typography>
                <div></div>
            </div>
            <div className={classes.bottomBox}>
                <div style={{
                    marginBottom: '5%'
                }}>
                    <div className={classes.leftBox}>
                        <label htmlFor="workout-images" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <img src={upload} alt="upload" width="76" height="68" />
                            <Typography component="h2" variant="h2" sx={{
                                fontSize: '15px',
                                margin: '24px 0 64px 0'
                                }}>
                                {image ? image?.split("\\")[2] : ".pdf format"}</Typography>
                            <Input
                                id="workout-images"
                                name="image-upload"
                                type="file"
                                accept="application/pdf"
                                sx={{display: 'none'}}
                                onChange={handleChange}
                            />
                            <Button component="div" className={classes.upload}>
                                <UploadFileIcon fontSize="15" />
                                {loading ? <img src={Loader} width="24" alt="Loader" /> : "Upload"}
                            </Button>
                        </label>
                    </div>
                </div>
                <div className={classes.rightBigBox}>
                    <div className={classes.verticalLine}></div>
                    <Typography component="h4" variant="h4" sx={{fontSize: '26px'}}>or</Typography>
                    <div className={classes.verticalLine}></div>
                </div>
                <div className={classes.rightBigBox} style={{
                    marginBottom: '5%'
                }}>
                    <Typography component="h2" variant="h2" className={classes.boxHeading}>
                        Fill the form</Typography>
                    <div className={classes.rightBox}>
                        <img src={form} alt="form_icon" />
                        {props.from === "product" &&
                            <Button className={classes.startButton} size="large" 
                                onClick={() => {dispatch(setProductDetailsById(null))}}>
                                <Link to={routes.NEW_PRODUCT} style={{
                                    color: '#fff',
                                }}>
                                    Start
                                </Link>
                            </Button>
                        }
                        {(location.pathname === "/university/registrations" ||
                            location.pathname === "/university/approved") &&
                            <Button 
                                className={classes.startButton} 
                                size="large"
                                onClick={() => {
                                    dispatch(setUniversityByIdData(null))
                                }}>
                                <Link to={routes.CREATE_UNIVERSITY} style={{
                                    color: '#fff',
                                }}>
                                    Start
                                </Link>
                            </Button>
                        }
                        {console.log(location.pathname)}
                        {location.pathname === "/coaching/registrations" &&
                            <Button 
                                className={classes.startButton} 
                                onClick={() => {
                                    dispatch(setUniversityByIdData(null))
                                }}
                                size="large">
                                <Link to={routes.CREATE_COACHING} style={{ 
                                    color: '#fff',
                                }}>
                                    Start
                                </Link>
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Listing;
